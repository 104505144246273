import { Divider, useMediaQuery } from "@material-ui/core";
import React from "react";
import { HomeSolutionsButton } from "./CustomButtons";

interface HomeSolutionsButtonGroupProps {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function HomeSolutionsButtonGroup(
  props: HomeSolutionsButtonGroupProps
) {
  const { activeIndex, setActiveIndex } = props;
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div
      style={{
        boxShadow: "0px 0px 15px rgb(85,85,85)",
        padding: 0,
        margin: 0,
        display: "flex",
        zIndex: 10,
        position: "relative",
        overflowX: "auto",
        overflowY: "hidden",
        scrollbarWidth: 'none'
      }}
      id='container'
    >
      {!isMobile ? (
        <Divider
          style={{
            width: "5%",
            height: "auto",
            borderTop: "1px solid rgba(255,255,255,0.7)",
            borderBottom: "1px solid rgba(255,255,255,0.7)",
            backgroundColor: "transparent",
            flexGrow: 1
          }}
        />
      ) : (
        []
      )}
      <HomeSolutionsButton
        index={0}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        Law <br/> Enforcement
      </HomeSolutionsButton>
      <HomeSolutionsButton
        index={1}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        Real Estate
      </HomeSolutionsButton>
      <HomeSolutionsButton
        index={2}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        Medical
      </HomeSolutionsButton>
      <HomeSolutionsButton
        index={3}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        Insurance
      </HomeSolutionsButton>
      <HomeSolutionsButton
        index={4}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        Retail
      </HomeSolutionsButton>
      <HomeSolutionsButton
        index={5}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        City Planning
      </HomeSolutionsButton>
      {!isMobile ? (
        <Divider
          style={{
            width: "5%",
            height: "auto",
            borderTop: "1px solid rgba(255,255,255,0.7)",
            borderBottom: "1px solid rgba(255,255,255,0.7)",
            backgroundColor: "transparent",
            flexGrow: 1
          }}
        />
      ) : (
        []
      )}
    </div>
  );
}
