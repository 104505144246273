import React from "react";
import DesktopBottomMap from "./CareersPageComponents/DesktopBottomMap";
import MobileBottomMap from "./CareersPageComponents/MobileBottomMap";
import "../components/css/BottomMap.css";

export default function BottomMap(props: any) {

  const { careersPage } = props

  return(
    <>
      <MobileBottomMap className="mobile map" careersPage={careersPage} />
      <DesktopBottomMap className="desktop map" careersPage={careersPage} />
    </>
  )
}