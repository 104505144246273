import { Box, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import RedHeader from "../RedHeader";
import TeamProfileBody from "./TeamProfileBody";
import "../css/TeamProfile.css";

interface Profile {
    name: string;
    position: string;
    bio: string;
    image: string;
}

export default function TeamProfile(props: {
    profile: Profile;
    isMobile?: boolean;
}) {
    const { bio, name, position, image } = props.profile;

    return (
        <Box
            className="profile container"
        >
            <div
                className="profile header"
            >
                <Box
                    style={{
                        backgroundColor: "#C80A13",
                        boxShadow: "0 0 10px red",
                        position: "absolute",
                        width: 6,
                        height: 6,
                        left: -3,
                        top: -3,
                    }}
                />
                <Box
                    style={{
                        backgroundColor: "#C80A13",
                        boxShadow: "0 0 10px red",
                        position: "absolute",
                        width: 6,
                        height: 6,
                        right: -3,
                        top: -3,
                    }}
                />
                <img
                    loading="lazy"
                    width= "100%"
                    height= "100%"
                    style={{
                        maxWidth: 700,
                        aspectRatio: "1/1",
                        objectFit: "cover"
                    }}
                    src={image}
                    alt="A team member at Crimer"
                />
                <RedHeader
                    style={{
                        padding: "8px 0px",
                        fontSize: "1em",
                        fontFamily: "xperia",
                        textAlign: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    {name}
                </RedHeader>
            </div>
            <Typography
                variant="h4"
                className="profile position"
                style={{
                    textAlign: "center",
                    paddingTop: 51,
                    paddingBottom: 16,
                    fontSize: isMobile ? "1.25" : "1.5rem",
                    fontWeight: 400,
                    color: "#C80A13",
                }}
            >
                {position}
            </Typography>
            <TeamProfileBody style={{ flex: 1 }}>{bio}</TeamProfileBody>
        </Box>
    );
}
