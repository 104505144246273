import { Box, Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { useRef, useState } from "react";
import IMAGES from "../ImageImport";
import "../css/BulletedParagraph.css";

const useStyles = makeStyles((theme) => ({
  "@keyframes rotateDown" :{
    "from":{
      transform: "rotate(0deg)"
    },
    "to":{
      transform: "rotate(90deg)"
    }
  },
  rotateD:{
    animation: `$rotateDown .5s`,
    transform: "rotate(90deg)"
  },
  "@keyframes rotateUp" :{
    "from":{
      transform: "rotate(90deg)"
    },
    "to":{
      transform: "rotate(0deg)"
    }
  },
  rotateU:{
    animation: `$rotateUp .5s`,
  },
}))

export default function BulletedParagraph(props: {
  children: any;
  titleCard?: string;
  isMobile?: boolean;
  isWide?: boolean;
}) {

  const classes = useStyles();
  const reference = useRef<HTMLImageElement>(null);
  const [isClicked, SetIsClicked] = useState(false);

  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
      <Box style={{ display: "flex", width: "100%",flexDirection: "row", alignItems: "center" }}>
        <Button
          variant="outlined"
          onClick={() => {
            SetIsClicked(!isClicked);
            if (reference.current){
              if(!isClicked){
                reference.current.className = classes.rotateD;
            }
            else{
              reference.current.className = classes.rotateU;
            }
          }
          }}
          >
          <img ref={reference} id="arrow" src={IMAGES.chevronBullet} alt= "Bullet point"/>
        </Button>
        <Typography >
          {props.titleCard}
        </Typography>
      </Box>
      <Divider style={{ width: 16 }} />
      {isClicked && <Typography
            className="description animation"
            >
        {props.children}
      </Typography>}
    </Box>
  );
}
