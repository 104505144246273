import { colors } from "./customTheme";
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import LaunchIcon from '@material-ui/icons/Launch';
import "../components/css/PressBox.css"


// we use this Typescript type to get better linting and error checking when passing props to this component
type PressBoxProp = {
  title: string;
  pressDate: string;
  summary: string;
  link: string;
  image: string;
};

export default function PressBox(props: PressBoxProp) {
  const displayDate = moment(Date.parse(props.pressDate)).format("MMM Do YYYY");

  return (
    <Box
      className="box container"
      style={{
        //backgroundImage: isMobile ? "linear-gradient(to top, #191D3B 50%, white 70%)" : "linear-gradient(to right, #191D3B, white 75%)",
      }}
    >
      <Box
        className="inner box"
      >
          <div className="mobile display">
            <img loading="lazy" style={{ height: 200, aspectRatio: '1:1' }} alt= "" src={`/images/${props.image}`} />
            <div style={{height: 240, width: "100%"}} />
          </div>
        {/* Title */}
        <Typography
          className="title text"
        >
          {props.title}
        </Typography>
        <Divider style={{ height: 16, backgroundColor: "transparent" }} />
        <Typography
          className="date text"
        >
          {displayDate}
        </Typography>
        <Divider
          className="divider height"
        />
        <Typography
          className="summary text"
        >
          {props.summary}
        </Typography>
        <Divider style={{ flex: 1, minHeight: 16, backgroundColor: "transparent" }} />
        <a
          className="read more"
          href={props.link}
          target="_blank"
          rel="noreferrer"
        >
          Read More{props.link.includes("crimer.com") ? "→" : <LaunchIcon style={{verticalAlign: "text-bottom"}}/>}
        </a>
      </Box>
      <div className="desktop display" style={{ flex: 1 }} />
        <div className="desktop display" style={{flex: 2}}>
          <img loading="lazy" style={{ width: '100%', }} alt= "" src={`/images/${props.image}`} />
        </div>
    </Box>
  );
}
