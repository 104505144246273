import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
  Snackbar
} from "@material-ui/core";
import { colors } from "./customTheme";
import { OutlinedButton } from "./CustomButtons";
import { useState } from "react";
import IMAGES from "./ImageImport";
import { Alert } from "@mui/material";
import "./css/HomeDemo.css"

const useStyles = makeStyles(() => ({
  input: {
    color: "white",
  },
}));

interface HomeDemoProps {
  demoFade: boolean;
}

export default function HomeDemo(props: HomeDemoProps) {
  //const { demoFade } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  const isWide = useMediaQuery("(min-width:2000px)");
  const [invalidEmail, setEmailInvalid] = useState(false);
  const [invalidName, setNameInvalid] = useState(false);
  const [invalidOrganization, setOrganizationInvalid] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [info, setInfo] = useState("");
  const [helperText, setHelperText] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, textField: number) => {
    switch (textField) {
      case 0: setEmail(event.target.value); break;
      case 1: setName(event.target.value); break;
      case 2: setOrganization(event.target.value); break;
      case 3: setInfo(event.target.value); break;
    }
  };

  const handleSubmit = () => {
    var noErrors = true;
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if ( email !== "" ){
      if (regexp.test(email)) {
      setEmailInvalid(false)
      console.log(`email: ${email}\nname: ${name}\norganization: ${organization}\ninfo: ${info}`)
      setOpen(true)
      }
      else {
        setHelperText("Invalid Email")
        setEmailInvalid(true)
        noErrors = false;
      }
    }
    else {
      setHelperText("This field is required")
      setEmailInvalid(true)
      noErrors = false;
    }
    if(name === ""){
      setNameInvalid(true)
      noErrors = false;
    }
    if(organization === ""){
      setOrganizationInvalid(true)
      noErrors = false;
    }

    if (noErrors) {
      const data = new FormData()
      data.append("email", email)
      data.append("name", name)
      data.append("organization", organization)
      data.append("info", info)
      fetch("https://api.staging.crimer.com/demo_requests/", {
        method: "POST",
        body: data
      }).then(res => setOpen(true)).catch(e => console.log(e))
    }
  }

  return (
    /*<Fade in={demoFade} timeout={0}>*/
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "center",
          zIndex: 1,
          backgroundColor: colors.darkGrey,
          position: "relative",
          paddingTop: 50,
          paddingBottom: 60,
          width:'100%'
        }}
      >
        <Box className="map back">
          <img
            src={IMAGES.worldMap}
            alt="map"
            style={{
              position: "absolute",
              height: "auto",
              width: "100%",
              minWidth: 0,
              maxWidth: 1500,
              opacity: 0.80,
              pointerEvents: 'none'
            }}
          />
        </Box>
          <Typography
          variant="h3"
          className="demo request"
        >
          Request a Demo
        </Typography>
        <Grid
          container
          direction="row-reverse"
          className="container width"
          spacing={isMobile ? 2 : 8}
        >
          <Grid
            item={true}
            container={true}
            justifyContent="center"
            lg={6}
            md={12}
            className="container text"
          >
            <Box
              className="description box"
            >
              <Typography
                variant="h3"
                style={{ paddingBottom: 16, fontSize: "1.5em",}}
              >
                Features
              </Typography>
              <Typography
                variant="body1"
                className= "description text"
              >
                CrimeRadar graphically displays predicted crime incidents by
                type, time and location. <br />
                <br />
                Access advanced user options from the dropdown menu. You can
                choose which types of predicted crime you’d like to see on the
                map, and how far CrimeRadar should look ahead.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item={true}
            container={true}
            justifyContent="center"
            alignItems="center"
            lg={6} md={12}>
            <FormControl
              className="form control"
            >
              <TextField
                fullWidth
                error = {invalidEmail}
                id= "Email"
                label="EMAIL"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 0)}
                className="text field"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.input }}
                helperText= { invalidEmail ? helperText : "" }
              />
              <TextField
                fullWidth
                id= "Name"
                label="NAME"
                value={name}
                error={invalidName}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 1)}
                className="text field"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.input }}
                helperText= { invalidName ? "This field is required" : "" }
              />
              <TextField
                fullWidth
                id= "Organization"
                label="ORGANIZATION"
                value={organization}
                error={invalidOrganization}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 2)}
                className="text field"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.input }}
                helperText= { invalidOrganization ? "This field is required" : "" }
              />
              <TextField
                fullWidth
                multiline
                maxRows={10}
                id= "Additional Info (Optional)"
                label="ADDITIONAL INFO (OPTIONAL)"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 3)}
                value={info}
                className="text field"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ className: classes.input }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <OutlinedButton
          onClick={handleSubmit}
          variant="contained"
          className= "submit button"
        >
          Submit
        </OutlinedButton>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={open} autoHideDuration={3000} onClose={() => setOpen(false)}>
          <Alert variant="filled" onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
          Message Sent!
        </Alert>
      </Snackbar>
      </Box>
    /*</Fade>*/
  );
}
