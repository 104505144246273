import "./App.css";
import HomePage from "./screens/HomePage";
import ContactUs from "./screens/ContactUs";
import AboutUs from "./screens/AboutPage";
import LawEnforcementPage from "./screens/LawEnforcementPage";
import Press from "./screens/Press";
import subscriptionAgreement from "./screens/SubscriptionAgreement";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RealEstate from "./screens/RealEstate";
import Medical from "./screens/Medical";
import Insurance from "./screens/Insurance";
import Retail from "./screens/Retail";
import CityPlanning from "./screens/CityPlanning";
import Demo from "./screens/Demo";
import PageNotFound from "./screens/PageNotFound";

import { theme } from "./components/customTheme";
import { ThemeProvider } from "@material-ui/styles";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Careers from "./screens/Careers";

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* did not put header or footer inside a switch since we always want it to appear */}
      <BrowserRouter>
        <ScrollToTop />
        {/* for the future, can add more paths here */}
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/contactus" exact component={ContactUs} />
          <Route path="/aboutus" exact component={AboutUs} />
          <Route path="/lawenforcement" exact component={LawEnforcementPage} />
          <Route path="/press" exact component={Press} />
          <Route path="/subscription" exact component={subscriptionAgreement} />
          <Route path="/privacy" exact component={PrivacyPolicy} />
          <Route path="/realestate" exact component={RealEstate} />
          <Route path="/medical" exact component={Medical} />
          <Route path="/insurance" exact component={Insurance} />
          <Route path="/retail" exact component={Retail} />
          <Route path="/cityplanning" exact component={CityPlanning} />
          <Route path="/demo" exact component={Demo} />
          <Route path="/careers" exact component={Careers} />
          <Route path="/404" exact component={PageNotFound} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
