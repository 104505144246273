import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
//import "react-responsive-carousel/lib/styles/carousel.min.css";
import TeamProfile from "../components/AboutPageComponents/TeamProfile";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import IMAGES from "../components/ImageImport";
import { isMobile } from "react-device-detect";
import "../components/css/AboutUs.css";

const profiles = [
  {
    name: "Alexander Adams",
    position: "Chief Executive Officer",
    bio: "An LSU graduate, Alexander has a passion for data science and crime prediction. In 2018, Alexander started the Crimer project – an initiative to bring crime prediction into the machine learning age – and has remained closely tied to the Louisiana startup scene ever since. Alexander maintains an interest in AI research, data science, and his alma mater’s computer science program.",
    image: isMobile ? IMAGES.profilePictures.LexMobile : IMAGES.profilePictures.Lex,
  },
  {
    name: "Charles Glass",
    position: "Chief Operating Officer",
    bio: "An LSU instructor specializing in cybersecurity, Charles first joined the Crimer team in the fall of 2018. Originally working on the front-end demo, Charles has since then been responsible for business operations. In his free time he enjoys Baduk, exercise, and playing piano.",
    image: isMobile ? IMAGES.profilePictures.CharlesMobile :IMAGES.profilePictures.Charles,
  },
  {
    name: "Scott Johnson",
    position: "Head of Engineering",
    bio: "A UC Berkeley Master of Engineering graduate focusing on Data Science and Systems, Scott began working for Crimer in the spring of 2019, creating the first version of its website. Scott’s hobbies include programming and video games.",
    image: isMobile ? IMAGES.profilePictures.ScottMobile :IMAGES.profilePictures.Scott,
  },
  {
    name: "TJ Mathews",
    position: "Head of Mobile Development",
    bio: "Taylor “TJ” Mathews is an LSU computer science graduate with a concentration in data science and analytics. TJ started working for Crimer in the summer of 2019 as a back-end developer. In his free time, he enjoys hiking, basketball, competitive video games, and learning the piano.",
    image: isMobile ? IMAGES.profilePictures.TJMobile :IMAGES.profilePictures.TJ,
  },
  {
    name: "Kymbrarly Hoyt",
    position: "Admin Assistant",
    bio: "From scheduling, to event organizing, to preparing the meeting minutes, Kym has a hand in most of the background operations at Crimer. When she’s not keeping the wheels spinning, she enjoys video games, painting, and chilling with her fiance and cats.",
    image: isMobile ? IMAGES.profilePictures.KymMobile :IMAGES.profilePictures.Kym,
  },
  {
    name: "William Woodfin",
    position: "Scrum Master",
    bio: "William is an LSU computer science graduate with a concentration in cybersecurity. When he’s not working with his team at Crimer, William enjoys workouts, video games, volleyball, and random educational videos on the internet.",
    image: isMobile ? IMAGES.profilePictures.WoodMobile :IMAGES.profilePictures.Wood,
  },
  {
    name: "Daniel Atkinson",
    position: "Adjunct Software Engineer",
    bio: "A spring 2021 LSU graduate, Daniel studied computer science with a concentration in software engineering. Daniel enjoys the guitar, listening to music, and playing competitive video games with his friends.",
    image: isMobile ? IMAGES.profilePictures.DanielMobile :IMAGES.profilePictures.Daniel,
  },
  {
    name: "Sunny Yi",
    position: "Head of User Experience",
    bio: "An art student at LSU, Sunny designs the user interface and manages the useability for many of Crimer’s projects. Sunny enjoys video games, watching anime, baking, playing with her cat Moony, and coding.",
    image: isMobile ? IMAGES.profilePictures.MadelineMobile :IMAGES.profilePictures.Madeline,
  },
];

export default function AboutPage() {
  useEffect(() => {
    document.title = "Crimer | About Us";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");

  const heroImage = isMobile ? IMAGES.aboutUsBackgroundMobile : IMAGES.aboutUsBackground;
  const teamImage = isMobile ? IMAGES.partnersInCrimeMobile : IMAGES.partnersInCrime;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
      <Hero
        heroImage={heroImage}
        heroTitle="About Us"
      />

        <Divider style={{ height: 54, backgroundColor: "transparent" }} />
        <RedHeader className="header text" >
          Leadership
        </RedHeader>
        <Divider style={{ height: 34, backgroundColor: "transparent" }} />
        <Typography className="about sub section">
          Behind the code, the multidimensional analyses, and the AI, Crimer was
          designed to do one thing: keep people safe. It doesn’t just stop at
          prediction. Crimer aims to provide policemen and civilians alike with
          the information they need to prepare for, and ultimately prevent,
          crime.
          <br />
          <br />
          Crimer isn’t a massive faceless corporation. They don’t want a
          monopoly, vertical or horizontal. Crimer is a small, focused team that
          believes in open source principles and works with a single goal in
          mind. Analyze, predict, inform.
          <br />
          <br />
          Because Crimer believes that people are safest when they’re informed
        </Typography>
        <Divider style={{ height: 150, backgroundColor: "transparent" }} />
          <MobileTeam/>
          <DesktopTeam/>
        <Divider
          className="team divider"
        />
        <Typography
          className="crime partners"
        >
          MEET THE PARTNERS IN CRIME
        </Typography>
        <Divider
          className="image divider"
        />
        <div className="team image">
          <img
            loading="lazy"
            src={IMAGES.teamBackground}
            alt=""
            style={{zIndex: "0", position:"absolute", height: "110%", width:"90%"}}
          />
          <img
            loading="lazy"
            src={teamImage}
            alt="The Crimer team"
            className="big image"
          />
        </div>
        <Divider style={{ height: 58, backgroundColor: "transparent" }} />
        <Typography className="about sub section">
          Crimer was founded in 2018 by computer scientists from Louisiana State
          University. Crimer believes in making the world a safer place through
          the application of machine learning and cutting-edge data science
          techniques. Crimer holds firm to the conviction that the principled
          and ethical advancement and application of AI and machine learning
          technologies is beneficial to humanity.
        </Typography>
        <Divider style={{ height: 100, backgroundColor: "transparent" }} />
      </Box>
    </PageTemplate>
  );
}

function DesktopTeam(){
    return (
      <Grid className="desktop display" container spacing={8}>
            {
              // converts the profiles array to a grid of TeamProfiles
              profiles.map((profile, index) => {
                return (
                  <>
                    {index > 5 && (
                        <Grid item sm={false} lg={1} style={{padding:0}}>
                        </Grid>
                      )}
                    <Grid item sm={6} lg={4}>
                      <TeamProfile profile={profile} />
                    </Grid>
                    {index > 5 && (
                        <Grid item sm={false} lg={1} style={{padding:0}}>
                        </Grid>
                      )}
                  </>
                );
              })
            }
          </Grid>
    );
}

function MobileTeam(){
    return (
      <Carousel
            showArrows={false}
            swipeable
            infiniteLoop
            emulateTouch
            showStatus={false}
            swipeScrollTolerance={40}
            preventMovementUntilSwipeScrollTolerance
            width="95vw"
            className="mobile display block"
          >
            {profiles.map((profile) => (
              <TeamProfile isMobile profile={profile} />
            ))}
          </Carousel>
    );
}
