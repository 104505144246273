import { Grid, Snackbar, TextField, useMediaQuery } from "@material-ui/core";
import { Alert } from "@mui/material";
import { useState } from "react";
import { OutlinedButton } from "../CustomButtons";
import "../css/Form.css";

export default function DemoForm(props: any) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [invalidEmail, isEmailInvalid] = useState(false);
  const [emptyName, isNameInvalid] = useState(false);
  const [emptyBody, isBodyInvalid] = useState(false);
  const [emptySubject, isSubjectInvalid] = useState(false);
  const [helperText, setHelperText] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    var noErrors = true;
    isEmailInvalid(false);
    isNameInvalid(false);
    isSubjectInvalid(false);
    isBodyInvalid(false);
    if (email !== "") {
      if (!regexp.test(email)) {
        setHelperText("Invalid Email");
        isEmailInvalid(true);
        noErrors = false;
      }
    } else {
      isEmailInvalid(true);
      setHelperText("Email cannot be empty");
      noErrors = false;
    }

    if (name === "") {
      isNameInvalid(true);
      noErrors = false;
    }

    if (subject === "") {
      isSubjectInvalid(true);
      noErrors = false;
    }

    if (body === "") {
      isBodyInvalid(true);
      noErrors = false;
    }

    if (noErrors) {
      const data = new FormData()
      data.append("from_email", email)
      data.append("name", name)
      data.append("subject", subject)
      data.append("body", body)
      fetch("https://api.staging.crimer.com/contacts/", {
        method: "POST",
        body: data
      }).then(res => setOpen(true)).catch(e => console.log(e))
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    textField: number
  ) => {
    switch (textField) {
      case 0:
        setEmail(event.target.value);
        break;
      case 1:
        setName(event.target.value);
        break;
      case 2:
        setSubject(event.target.value);
        break;
      case 3:
        setBody(event.target.value);
        break;
    }
  };

  return (
    <Grid
      item
      container
      justify="center"
      alignContent="flex-start"
      xs={12}
      className="form container"
    >
      <form
        className="demo form style"
      >
        <TextField
          fullWidth
          label="EMAIL"
          value={email}
          error={invalidEmail}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e, 0)
          }
          className="text field"
          InputProps={{ className: "form input" }}
          inputProps={{"aria-label":"Email"}}
          InputLabelProps={{ className: "form input" }}
          helperText={invalidEmail ? helperText : null}
        />
        <div
          className="form divider"
        />
        <TextField
          fullWidth
          label="NAME"
          value={name}
          error={emptyName}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e, 1)
          }
          className="text field"
          InputProps={{ className: "form input" }}
          inputProps={{"aria-label":"Name"}}
          InputLabelProps={{ className: "form input" }}
          helperText={emptyName ? "Name cannot be empty" : null}
        />
        <div
          className="form divider"
        />
        <TextField
          fullWidth
          label="SUBJECT"
          value={subject}
          error={emptySubject}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e, 2)
          }
          className="text field"
          InputProps={{ className: "form input" }}
          inputProps={{"aria-label":"Subject"}}
          InputLabelProps={{ className: "form input" }}
          helperText={emptySubject ? "Subject cannot be empty" : null}
        />
        <div
          className="form divider"
        />
        <TextField
          fullWidth
          multiline
          maxRows={4}
          label="BODY"
          value={body}
          error={emptyBody}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e, 3)
          }
          className="text field"
          InputProps={{ className: "form input"}}
          inputProps={{"aria-label":"Body"}}
          InputLabelProps={{ className: "form input"}}
          helperText={emptySubject ? "Body cannot be empty" : null}
        />
        <div
          className="form divider"
        />
        <OutlinedButton
          onClick={handleSubmit}
          variant="contained"
          className="demo submit"
        >
          <div
          style={{
            width: "100%"
          }}>
            Submit
          </div>
        </OutlinedButton>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Message Sent!
        </Alert>
      </Snackbar>
    </Grid>
  );
}
