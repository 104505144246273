import {
  Box,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import BlueImage from "../components/SolutionPagesComponents/BlueImage";
import BorderedDivider from "../components/SolutionPagesComponents/BorderedDivider";
import BulletedParagraph from "../components/SolutionPagesComponents/BulletedParagraph";
import IMAGES from "../components/ImageImport";
import HomeDemo from "../components/HomeDemo";
import "../components/css/SolutionPageStyles.css"

const heroTitle = "Retail";

export default function Retail() {
  useEffect(() => {
    document.title = "Crimer | Retail";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");

  const heroImage = isMobile ? IMAGES.solutionPageImages.retail.heroImageMobile : IMAGES.solutionPageImages.retail.heroImage;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Hero Image and Title */}
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 55, backgroundColor: "transparent" }} />
        <RedHeader
          className="solution header"
        >
          Fostering Trust
        </RedHeader>
        <Divider style={{ height: 35, backgroundColor: "transparent" }} />
        <Typography className="subsection solution desktop display">
        What brings customers to your doors isn’t just competitive prices. It isn’t
        just the quality of your products or the flashiness of your advertising.
        What keeps customers coming back is reliability, and the reputation that it brings.
        <br/><br/>
        Crime hurts your business, not just because of losses due to theft or robbery.
        The presence of crime in or around businesses can crucially damage a brand.
        No one wants to shop somewhere when their personal safety is at risk, and
        no one wants to bother ordering when they know their deliveries are likely to be stolen.
        <br/><br/>
        Crimer keeps you ahead of crime trends in your area. Unlike traditional
          hotspot methods, Crimer includes far more than just past crime data in
          its calculations. Crimer considers weather, terrain, time, location,
          population density, and other factors to accurately track crime trends,
          even up to several years in advance.
        </Typography>
        <Typography className="subsection solution mobile display">
        It’s not something customers always look for, but it’s something every customer demands: trust and reliability. Crime presence hurts both.
        <br/><br/>
        Crimer’s cutting edge machine-learning algorithms let you plan around predicted crime presence, keeping you one step ahead of crime, and the competition.
        </Typography>
        <Divider style={{ height: 70, backgroundColor: "transparent" }} />
        <Box
          className="header box"
        >
          <BorderedDivider className="solution desktop display" style={{ width: "9.5vw" }} />
          <RedHeader className="solution header">
          Protect your {!isMobile && <br />}Bottom Line
          </RedHeader>
          <BorderedDivider className="solution desktop display" style={{ flex: 1 }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage showRedLine className="solution mobile display" style={{ marginBottom: 20 }} src={IMAGES.solutionPageImages.retail.sub1Mobile} />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
            <BlueImage showRedLine className="solution desktop display" style={{ marginRight: 50, maxWidth: 800 }} src={IMAGES.solutionPageImages.retail.sub1} />
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Area risk analysis">
            Avoid losses from theft and reduce insurance premiums by operating in low risk areas.
            Reports of theft and missing deliveries can take up a considerable amount
            of customer service calls. Build trust and save precious time communicating
            with your customers. Crimer lets you focus on the relations building aspect
            of customer service.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Scalable savings">
            Crimer’s actionable information platform provides benefits for organizations
            of any size. As your business grows, Crimer’s potential savings scale up with you.
            </BulletedParagraph>
          </Box>
        </Box>

        <Divider className="solution divider" />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
          <RedHeader className="solution header">
          The Crimer {!isMobile && <br />}Advantage
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
        </Box>
        <Divider className="image divider" />
            <BlueImage
              className= "solution mobile display"
              showRedLine
              style={{ marginBottom: 20 }}
              src={IMAGES.solutionPageImages.retail.sub2Mobile}
            />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Intelligent theft prevention solutions">
            Fight porch piracy by requiring delivery companies to request signatures
            in high risk zones, and alert customers electronically when delivering to
            these locations.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Soft security">
            Predict and prevent shoplifting in brick and mortar businesses, improving
            the general perception of security in your location.
            <br></br><br></br>
            Take control of your business. Crimer’s easy to use, extremely customizable
            prediction platform is tailor-made to suit your individual needs and preferences.
            </BulletedParagraph>
          </Box>
            <BlueImage
              showRedLine
              className='solution desktop display'
              style={{ marginLeft: 50, maxWidth: 800 }}
              src={IMAGES.solutionPageImages.retail.sub2}
            />
        </Box>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
      </Box>
      <HomeDemo  demoFade={false}/>
    </PageTemplate>
  );
}
