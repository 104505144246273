import { Box, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import IMAGES from "./ImageImport";
import './css/ArrowDivider.css';

const useStyles = makeStyles(() => ({
    arrowSliding: {
        '-webkit-animation': 'slide 4s linear infinite',
        animation: 'slide 4s linear infinite',
    },
    buttonBackground: {
        height: "100%",
        padding: 0,
        zIndex: 100,
        "&:hover": {
            backgroundColor: "transparent",
        }
    }

}));

export default function ArrowDivider(props: any) {
    const classes = useStyles();
    const mobileSolutionPageViewHeightModifier = .6;
    const isMobile = useMediaQuery("(max-width:600px)");

    const buttonTopLocation = isMobile ? window.innerHeight * mobileSolutionPageViewHeightModifier : window.innerHeight;
    return (
        <Box
            className="arrow animation"
            style={props.style} >
            <img src={IMAGES.splashPageBottomeLeftLine} alt="Left-side dividing line"
                style={{ flex: 1, minWidth: 0, height: 20}}/>
            <Button
                onClick={() => window.scroll({
                    top: buttonTopLocation,
                    left: 0,
                    behavior: "smooth",
                  })}
                className={classes.buttonBackground}>
                <img
                    src={IMAGES.splashPageArrowSVG}
                    alt="Downward-pointing arrow"
                    loading="lazy"
                    className='arrow-image'
                />
            </Button>
            <img src={IMAGES.splashPageBottomeRightLine} alt="Right-side dividing line"
                 style={{ flex: 1, minWidth: 0, height: 20 }}/>

            <Box
            className="gradient box"
            />
        </Box>
    );
}
