import { Grid, Snackbar, TextField, Typography } from "@material-ui/core";
import { Alert } from "@mui/material";
import React, { useRef, useState } from "react";
import { OutlinedButton } from "../CustomButtons";
import { colors } from "../customTheme";
import {CoverLetter, Resume} from "../../assets/icons/Icons"
import "../css/Form.css";

export default function Form(props: any) {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFileCover, setSelectedFileCover] = useState<any>(null);
  const [helperText, setHelperText] = useState("");
  const [isCoverPicked, setIsCoverPicked] = useState(false);
  const [name, setName] = useState<string>("")
  const [emptyName, setIsNameInvalid] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)

  const fileInput = useRef<HTMLInputElement>(null);
  const coverInput = useRef<HTMLInputElement>(null);

  const handleSubmission = () => {
    var noErrors = true;
    if(name.trim() === ""){
      setIsNameInvalid(true);
      noErrors = false;
    }
    if(!selectedFile){
      setIsFilePicked(false);
      noErrors = false;
      setHelperText("Resume must be uploaded");
    }
    if(noErrors){
      const data = new FormData()
      data.append("name", name)
      data.append("resume", selectedFile)
      data.append("additional_info", additionalInfo)
      data.append("cover_letter", selectedFileCover)
      fetch("https://api.staging.crimer.com/careers/", {
        method: "POST",
        body: data
      }).then(res => setOpen(true)).catch(e => console.log(e))
    }
  }

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0] ? event.target.files[0] : selectedFile);
    setIsFilePicked(true);
    setHelperText("");
  };

  const coverChangeHandler = (event: any) => {
    setSelectedFileCover(event.target.files[0] ? event.target.files[0] : selectedFileCover);
    setIsCoverPicked(true);
  };

  const fileNameDisplay = (fileName : string) => {
    let displayName : string;
    let extension : number = fileName.lastIndexOf('.');

    if (extension < 0){
      displayName = fileName.substring(0, 15) + "...";
    }
    else if(extension > 15){
      displayName = fileName.substring(0, 15) + "..." + fileName.substring(extension + 1);
    }
    else{
      displayName = fileName
    }
    return displayName;
  }

  return (
    <Grid
      item
      container
      className="form container"
      justify="center"
      alignContent="flex-start"
      xs={12}
    >
      <form
        className="form style"
      >
        <TextField
          fullWidth
          label="NAME"
          value={name}
          error={emptyName}
          helperText={emptyName ? "Name cannot be empty" : null}
          onChange={(e) => {setName(e.target.value); setIsNameInvalid(false)}}
          className="text field"
          InputProps={{ className: "form input" }}
          inputProps={{ "aria-label":"Name" }}
          InputLabelProps={{ className: "form input" }}
        />
        <div
          className="form divider"
        />
        <TextField
          fullWidth
          multiline
          maxRows={6}
          label="ADDITIONAL INFO (OPTIONAL)"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          className="text field"
          InputProps={{ className: "form input" }}
          inputProps={{ "aria-label":"Additional Info (optional)" }}
          InputLabelProps={{ className: "form input" }}
        />
        <div
          className="form divider"
        />
        <div className="text field">
          <Typography style={{color: helperText ? "red" : ""}}>Upload a resume</Typography>
          {helperText && <Typography style={{color:"red", fontSize:".7rem", letterSpacing: 2}}>{helperText}</Typography>}
          <input
            style={{ display: "none" }}
            ref={fileInput}
            type="file"
            name="file"
            onChange={changeHandler}
          />
          <OutlinedButton
            variant="contained"
            style={{
              fontSize: 15,
              letterSpacing: "3px",
              width: "100%",
              paddingLeft:0,
              paddingRight:0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              borderColor: helperText ? "red" : "white"
            }}
            error={helperText ? true : false}
            onClick={() =>
              fileInput !== null ? fileInput!.current!.click() : null
            }
          >
              <div
              style={{
                width: "20%"

              }}>
                <Resume
                style={{
                  fill: helperText ? "red" : ""
                }}/>
              </div>
              <div
              style={{
                width: "60%",
                overflow: "hidden",
                color: helperText ? "red" : ""
              }}>
                {selectedFile ? fileNameDisplay(selectedFile.name) : "Choose Resume"}
              </div>

          </OutlinedButton>
        </div>
        <div className="text field">
          <Typography>Upload a cover letter (optional)</Typography>
          <input
            style={{ display: "none" }}
            ref={coverInput}
            type="file"
            name="file"
            onChange={coverChangeHandler}
          />
          <OutlinedButton
            variant="contained"
            style={{
              fontSize: 15,
              letterSpacing: "3px",
              width: "100%",
              paddingLeft:0,
              paddingRight:0,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() =>
              coverInput !== null ? coverInput!.current!.click() : null
            }
          >
              <div
              style={{
                width: "20%"
              }}>
                <CoverLetter/>
              </div>
              <div
              style={{
                width: "60%",
                overflow: "hidden",
              }}>
                {isCoverPicked ? fileNameDisplay(selectedFileCover.name) : "Choose Cover Letter"}
              </div>
          </OutlinedButton>
        </div>

        <OutlinedButton
          variant="contained"
          style={{
            marginTop: 96,
            marginBottom: 16,
            padding: "20px 0px",
            fontSize: 15,
            letterSpacing: "3px",
          }}
          onClick={handleSubmission}
        >
          <div
          style={{
            width: "100%"
          }}>
            Submit
          </div>

        </OutlinedButton>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Message Sent!
        </Alert>
      </Snackbar>
    </Grid>
  );
}
