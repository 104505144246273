import {
  Box,
  Button,
  makeStyles,
  createTheme,
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import { colors } from "../components/customTheme";
import { Link } from "react-router-dom";
import { useState } from "react";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Menu, Close } from "@material-ui/icons";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import IMAGES from "./ImageImport";

const breakpointValues = {
  xs: 0,
  sm: 280,
  md: 990,
  lg: 1010,
  xl: 1200,
};

const downArrowVectorAsset = (
  <svg
    width="15"
    height="10"
    viewBox="-2.5 -5 75 60"
    preserveAspectRatio="none"
  >
    <path
      d="M0,0 l35,50 l35,-50"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="5"
    />
  </svg>
);

const breakpoints = createBreakpoints({ values: breakpointValues });
const theme = createTheme({ breakpoints });

const useStyles = makeStyles(() => ({
  text: {
    color: colors.lightGrey,
    //fontSize: "1em",
    fontFamily: "xperia",
    letterSpacing: "3px",
    fontWeight: 600,
  },
  mobileSubText: {
    color: colors.white,
    //fontSize: "0.8em",
    fontFamily: "xperia",
    letterSpacing: "3px",
  },
  dropdownMenu: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: 150,
  },
  animatedDropdown: {
    display: "flex",
    transition: 'opacity .2s, backgroundColor .5s',
    flexDirection: "column",
  },
  noHoverButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  hoverButton: {
    width: "100%",
    fontSize: ".8rem",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem"
    },
    [theme.breakpoints.up(2000)]: {
      fontSize: "1.5rem"
    },
    "&:hover": {
      color: "black",
    },
  },
  mobile: {
    [theme.breakpoints.down("xs")]: {
      display: "inline-flex",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  browser: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
}));

interface HeaderProps {
  open?: boolean;
}

export default function Header(props: HeaderProps) {
  return (
    <>
      <BrowserHeader />
      <MobileHeader />
    </>
  );
}

function BrowserHeader() {
  const classes = useStyles();
  const [aboutDropdownIsVisible, setAboutDropdownIsVisible] = useState(false);
  const [solutionsDropdownIsVisible, setSolutionsDropdownIsVisible] =
    useState(false);

  return (
    <Box
      className={classes.browser}
      style={{
        boxSizing: "border-box",
        position: "absolute",
        top: 0,
        width: "100%",
        paddingLeft: "5%",
        paddingRight: "5%",
        zIndex: 10000,
        alignItems: "start",
        fontSize: 12
      }}
    >
      <Link to="/" style={{ textDecoration: "none" }}>
        <img
          src={IMAGES.crimerLockup}
          alt="Crimer logo"
          loading="lazy"
          style={{
            height: 24,
            paddingTop: 16,
            width: "auto",
            pointerEvents: "none",
          }}
        />
      </Link>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          minWidth: 0,
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button className={classes.hoverButton}>Home</Button>
        </Link>

        <Box
          onMouseEnter={() => setSolutionsDropdownIsVisible(true)}
          onMouseLeave={() => setSolutionsDropdownIsVisible(false)}
          onClick={() => setSolutionsDropdownIsVisible(true)}
          className={classes.dropdownMenu}
          alignSelf="flex-start"
          style={{
            backgroundColor: solutionsDropdownIsVisible
              ? colors.darkBlue
              : "transparent",
          }}
        >
          <Button classes={{ text: classes.hoverButton }}>
            Solutions &nbsp;{downArrowVectorAsset}
          </Button>
          <Box
            className={classes.animatedDropdown}
            style={{
              opacity: solutionsDropdownIsVisible ? 1 : 0,
              backgroundColor: solutionsDropdownIsVisible
              ? colors.darkBlue
              : "transparent",
            }}
          >
            <Link to="/lawenforcement" style={{ textDecoration: "none", display: solutionsDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>
                Law
                <br />
                Enforcement
              </Button>
            </Link>
            <Link to="/realestate" style={{ textDecoration: "none", display: solutionsDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>
                Real
                <br />
                Estate
              </Button>
            </Link>
            <Link to="/medical" style={{ textDecoration: "none", display: solutionsDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Medical</Button>
            </Link>
            <Link to="/insurance" style={{ textDecoration: "none", display: solutionsDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Insurance</Button>
            </Link>
            <Link to="/retail" style={{ textDecoration: "none", display: solutionsDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Retail</Button>
            </Link>
            <Link to="/cityplanning" style={{ textDecoration: "none", display: solutionsDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>
                City
                <br />
                Planning
              </Button>
            </Link>
          </Box>
        </Box>
        <Box
          onMouseEnter={() => setAboutDropdownIsVisible(true)}
          onMouseLeave={() => setAboutDropdownIsVisible(false)}
          onClick={() => setAboutDropdownIsVisible(true)}
          className={classes.dropdownMenu}
          alignSelf="flex-start"
          style={{
            backgroundColor: aboutDropdownIsVisible
              ? colors.darkBlue
              : "transparent",
          }}
        >
          <Button classes={{ text: classes.hoverButton }}>
            About &nbsp;{downArrowVectorAsset}
          </Button>
          <Box
            className={classes.animatedDropdown}
            style={{
              opacity: aboutDropdownIsVisible ? 1 : 0,
              backgroundColor: aboutDropdownIsVisible
              ? colors.darkBlue
              : "transparent",
            }}
          >
            <Link to="/aboutus" style={{ textDecoration: "none", display: aboutDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Team</Button>
            </Link>
            <Link to="/careers" style={{ textDecoration: "none", display: aboutDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Careers</Button>
            </Link>
            <Link to="/press" style={{ textDecoration: "none", display: aboutDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Press</Button>
            </Link>
            <Link to="/demo" style={{ textDecoration: "none", display: aboutDropdownIsVisible ? "flex" : "none" }}>
              <Button className={classes.hoverButton}>Demo</Button>
            </Link>
          </Box>
        </Box>
        <Link to="/contactus" style={{ textDecoration: "none" }}>
          <Button className={classes.hoverButton}>Contact</Button>
        </Link>
      </Box>
    </Box>
  );
}

function MobileHeader() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "10vh";
    setOpen(true);
  };
  const handleDrawerClose = () => {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('height');
    setOpen(false);
  };
  const doesHeaderNeedMinWidth = useMediaQuery("(min-height:750px)");

  return (
    <Box
      className={classes.mobile}
      style={{
        boxSizing: "border-box",
        position: "absolute",
        top: 0,
        width: "100%",
        paddingLeft: "5%",
        zIndex: 10000,
      }}
    >
      {/* This link and the next box container are displayed when the drawer is closed */}
      <Link to="/" style={{ textDecoration: "none" }}>
        <img
          src={IMAGES.crimerLockup}
          alt="Crimer logo"
          loading="lazy"
          style={{ height: 24, paddingTop: 16, width: "auto" }}
        />
      </Link>
      <Box
        onClick={handleDrawerOpen}
        style={{
          float: "right",
          justifyContent: "center"
        }}
      >
        <Button
          aria-label="Menu for page links"
          style={{
            padding: "0px",
            paddingTop: 6,
          }}
        >
          <Menu style={{ color: "white", height: 40, width: 40 }} />
        </Button>
      </Box>
      {/* This is everything displayed when the drawere is  */}
      <MUIDrawer open={open} variant="persistent" anchor="left">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.darkGrey,
            minHeight: doesHeaderNeedMinWidth ? '100vh' : 'auto'
          }}
        >
          <Box
            style={{
              flexDirection: "row",
              backgroundColor: "#3C3C3C",
              height: 80,
            }}
          >
            <img
              src={IMAGES.crimerLockup}
              alt="Crimer logo"
              loading="lazy"
              style={{
                height: 24,
                paddingTop: 16,
                width: "auto",
                paddingLeft: 16,
              }}
            />
            <Button
              onClick={handleDrawerClose}
              aria-label="Close page link menu"
              style={{
                padding: "0px",
                paddingTop: 6,
                float: "right",
              }}
            >
              <Close style={{ color: "white", height: 40, width: 40 }} />
            </Button>
          </Box>
          <List
            style={{
              width: "100vw",
            }}
          >
            <ListItem
              button
              key="Home"
              component={Link}
              to="/"
              onClick={handleDrawerClose}
              className={classes.mobileSubText}
              style={{
                height: "2.8em",
                color: "white",
            }}>
              <Box className={classes.text} style={{color: 'white'}}>Home</Box>
            </ListItem>
            <ListItem
              button
              key="Contact"
              component={Link}
              to="/contactus"
              onClick={handleDrawerClose}
              className={classes.mobileSubText}
              style={{
                height: "2.8em",
                color: "white",
            }}>
              <Box className={classes.text} style={{color: 'white'}}>Contact</Box>
            </ListItem>
            <Divider key="div 1" style={{ height: "2em", opacity: 0 }} />
            <ListItem key="Solutions">
              <Box className={classes.text}>Solutions</Box>
            </ListItem>
            {[
              { text: "Law Enforcement", page: "/lawenforcement" },
              { text: "Real Estate", page: "/realestate" },
              { text: "Medical", page: "/medical" },
              { text: "Insurance", page: "/insurance" },
              { text: "Retail", page: "/retail" },
              { text: "City Planning", page: "/cityplanning" },
            ].map((item) => {
              return (
                <ListItem
                  button
                  component={Link}
                  key={item.text}
                  to={item.page}
                  onClick={handleDrawerClose}
                  className={classes.mobileSubText}
                  style={{
                    height: "2.8em",
                    color: "white",
                  }}
                >
                  {item.text}
                </ListItem>
              );
            })}
            <Divider key="div 2" style={{ height: "2em", opacity: 0 }} />
            <ListItem key="About">
              <Box className={classes.text}>About</Box>
            </ListItem>
            {[
              { text: "Team", page: "/aboutus" },
              { text: "Careers", page: "/careers" },
              { text: "Press", page: "/press" },
              { text: "Demo", page: "/demo" },
            ].map((item) => {
              return (
                <ListItem
                  button
                  component={Link}
                  key={item.text}
                  to={item.page}
                  onClick={handleDrawerClose}
                  className={classes.mobileSubText}
                  style={{
                    height: "2.8em",
                  }}
                >
                  {item.text}
                </ListItem>
              );
            })}
            <Divider key="div 3" style={{ height: "2em", opacity: 0 }} />

          </List>
          <div style={{display: 'flex', flexGrow: 1, alignItems: 'flex-end'}}>
            <Box
            style={{ backgroundColor: "#3C3C3C", display: "flex", minHeight: 80, width: '100%' }}
                    >
            <Box
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                display: "flex",
                color: "white",
                width: "25%",
              }}
            >
              <FaLinkedinIn size="20" />
            </Box>
                    </Box>
          </div>
        </Box>
      </MUIDrawer>
    </Box>
  );
}
