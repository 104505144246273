import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import BlueImage from "../components/SolutionPagesComponents/BlueImage";
import BorderedDivider from "../components/SolutionPagesComponents/BorderedDivider";
import BulletedParagraph from "../components/SolutionPagesComponents/BulletedParagraph";
import IMAGES from "../components/ImageImport";
import HomeDemo from "../components/HomeDemo";
import "../components/css/SolutionPageStyles.css"

const heroTitle = "Emergency Medical Services";

export default function Medical() {
  useEffect(() => {
    document.title = "Crimer | Medical";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");

  const heroImage = isMobile ? IMAGES.solutionPageImages.medical.heroImageMobile : IMAGES.solutionPageImages.medical.heroImage;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Hero Image and Title */}
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 55, backgroundColor: "transparent" }} />
        <RedHeader
          className="solution header"
        >
          In the Right Place, at the Wrong Time
        </RedHeader>
        <Divider style={{ height: 35, backgroundColor: "transparent" }} />
        <Typography className="subsection">
        Ambulances can’t be everywhere. In order to swiftly respond to accidents and violent crimes,
        emergency medical services have to choose where they can afford to station and patrol their vehicles.
        They don’t always have the information they need to choose correctly.
        </Typography>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
        <Box
          className="header box"
        >
          <BorderedDivider className="solution desktop display" style={{ width: "9.5vw" }} />
          <RedHeader className="solution header">
          A Newer {!isMobile && <br />}Solution
          </RedHeader>
          <BorderedDivider className="solution desktop display" style={{ flex: 1 }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage showRedLine className="solution mobile display" style={{ marginBottom: 20 }} src={IMAGES.solutionPageImages.medical.sub1Mobile} />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
            <BlueImage showRedLine className="solution desktop display" style={{ marginRight: 50, maxWidth: 800 }} src={IMAGES.solutionPageImages.medical.sub1} />
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Intelligent Predictions">
            There’s a limit to what hotspot methods can say about future crime incidence.
            Without accounting for the many other factors that affect crime risk, hotspots
            can only show where crimes have occurred in the past. Crimer’s sophisticated platform
            considers weather, events, terrain, police presence and a variety of other factors in
            its predictions, making it one of the most reliable crime prediction services on the market.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Custom Solutions">
            Different areas require different solutions. That’s why Crimer’s easy to use,
            extremely customizable prediction platform is tailor-made to suit your individual
            needs and preferences
            </BulletedParagraph>
          </Box>
        </Box>
        <Divider className="solution divider" />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
          <RedHeader className="solution header">
            Saving Time,{!isMobile && <br />} Saving Lives
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage
            className= "solution mobile display"
            showRedLine
            style={{ marginBottom: 20 }}
            src={IMAGES.solutionPageImages.medical.sub2Mobile}
          />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%"}}>
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Path Efficiently">
            Keep your ambulances closer to where they will be needed.
            Crimer shows probable high-risk zones that require extra attention and resources.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Staff as Needed">
            Tired EMTs are error-prone EMTs. Keep your staff in
            their best conditions by anticipating periods of high
            and low activity through Crimer’s predictions.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Sort by Crime">
            Separately view predictions for violent crime and drug abuse.
            Crimer shows exactly what sort of risks your staff will likely face.
            <br></br><br></br>
            Be prepared. Crimer keeps you ahead of crime trends so you’re always
            ready for even the most rapid developments.


            </BulletedParagraph>
          </Box>
            <BlueImage
              showRedLine
              className='solution desktop display'
              style={{ marginLeft: 50, maxWidth: 800 }}
              src={IMAGES.solutionPageImages.medical.sub2}
            />
        </Box>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
      </Box>
      <HomeDemo  demoFade={false}/>
    </PageTemplate>
  );
}
