import { Box, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { colors } from "./customTheme";
import { useState } from "react";
import HomeSolutionsButtonGroup from "./HomeSolutionsButtonGroup";
import HomeSolutionContent from "./HomeSolutionContent";
import Testimonials from "./Testimonials";
import HomePress from "./HomePress";
import IMAGES from "./ImageImport";
import "./css/HomeSolutions.css";

const useStyles = makeStyles((theme) => ({
  landingLogoContainer: {
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

interface HomeSolutionsProps {
  solutionsFade: boolean;
}

export default function HomeSolutions(props: HomeSolutionsProps) {

  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const getSolution = () => {
    return <HomeSolutionContent setActiveIndex={setActiveIndex} activeIndex={activeIndex} />;
  };
  return (
    <Box>
      <HomeSolutionsButtonGroup
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          textAlign: "center",
          zIndex: 2,
          background: colors.darkBlue,
          position: "relative",
        }}
      >
        <Box className="landing logo container">
          <img
            src={IMAGES.chevronBullet}
            className="left chevron"
            alt=""
          />
        </Box>
        <Box className="landing logo container">
          <img
            src={IMAGES.chevronBullet}
            className="right chevron"
            alt=""
          />
          <Box
            style={{
              position: "absolute",
              zIndex: 1,
              height: 200,
              width: "100%",
              backgroundColor: colors.darkGrey,
              bottom: 0,
            }}
          ></Box>
          <Box
            style={{
              position: "absolute",
              zIndex: 3,
              height: 100,
              width: "100%",
              backgroundColor: colors.darkGrey,
              bottom: 0,
            }}
          ></Box>
        </Box>
        <Box className="landing logo container">
          <img
            src={IMAGES.policeBack}
            alt=""
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              pointerEvents: 'none',
              minWidth: 1500,
              right: 0,
            }}
          />
        </Box>
        {getSolution()}

        <Box
          style={{
            position: "relative",
            zIndex: 10,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Typography
            variant="h3"
            style={{
              zIndex: 10,
              paddingBottom: isMobile ? 30 : 16,
              fontSize: isMobile ? "1.5rem" : "auto",
              width: isMobile ? "90%" : "100%",
            }}
          >
            Testimonials
          </Typography>
          <img
            src={IMAGES.straighQuotes}
            alt=""
            loading="lazy"
            style={{
              paddingTop: isMobile ? 10 : 96,
              paddingBottom: isMobile ? 40 : 96,
              width: isMobile ? "40%" : "auto",
            }}
          />
          <Testimonials /> */}
          <Box
            className="solution press box">
            <Typography
              variant="h3"
              className="solution press text"
            >
              Press
            </Typography>
          </Box>

          <HomePress />

        </Box>
      </Box>
    </Box>
  );
}
