import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import { colors } from "./customTheme";
import { Link } from "react-router-dom";
import { FaLinkedinIn } from "react-icons/fa";
import "./css/Footer.css";
import IMAGES from "./ImageImport";

export default function Footer() {

  const isMobile = useMediaQuery("(max-width:599px)"); //the usual 600px for mobile was causing display issues right on that point

  const year = new Date().getFullYear();
  return (
    <Grid
      container={true}
      justifyContent="center"
      style={{
        width: "100%",
        paddingTop: 90,
        paddingLeft: "5%",
        paddingRight: "5%",
        textAlign: "left",
        backgroundColor: colors.darkGrey,
        position: "relative",
      }}
    >
      <Grid item={true} xs={12} style={{ position: "relative", zIndex: 100}}>
        <Grid container={true} spacing={3} justifyContent="center">
          <Grid
            item={true}
            container={true}
            xs={6}
            sm={8}
            md={9}
            lg={10}
            className="grid start"
            alignItems="center"
          >
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <img
                src={IMAGES.crimerLockup}
                alt="Crimer logo"
                loading="lazy"
                style={{ maxWidth: 180, height: "auto", width: "100%" }}
              />
            </Link>
          </Grid>
          <Grid
            container
            item={true}
            xs={3}
            sm={4}
            md={3}
            lg={2}
            className="grid end"
            style={{ justifySelf: "center" }}
          >
            <Grid
              item={true}
              container={true}
              xs={4}
              className="grid end"
              style={{ display: "inherit" }}
            >
              <a
                href="https://www.linkedin.com/company/crimer/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  style={{
                    marginLeft: 16,
                    marginRight: 16,
                    color: colors.white,
                  }}
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </IconButton>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Divider light style={{ height: 56, backgroundColor: "transparent" }} />
        <Box
          style={{
            height: 1,
            backgroundColor: "rgb(255,255,255,0.2)",
            width: "100%",
          }}
        />
        <Divider light style={{ height: 64, backgroundColor: "transparent" }} />

        <Grid container spacing={2}>
          <Grid
            item
            container
            sm={2}
            lg={3}
            direction="row"
            className="tablet flex"
            style={{ display: "flex", paddingTop: "4px" }}
          >
            <Grid
              item
              className="mobile alignment"
            >
              <Link
                to="/"
                style={{ textDecoration: "none",}}
                className="link text"
              >
                Home
              </Link>
            </Grid>
            <Grid
              item
              className="mobile display"
              style={{
                width:"50%",
                textAlign:"right",
                justifyContent: "flex-end",
              }}
              >
                <Link
                  to="/contactus"
                  style={{ textDecoration: "none"}}
                  className="link text"
                >
                  Contact
                </Link>
              </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sm={isMobile ? 6 : 4}
            lg={3}
            className="tablet flex"
            direction="column"
          >
            <Grid item className="link text">
              Solutions
            </Grid>
            {[
              { text: "Law Enforcement", page: "/lawenforcement" },
              { text: "Real Estate", page: "/realestate" },
              { text: "Medical", page: "/medical" },
              { text: "Insurance", page: "/insurance" },
              { text: "Retail", page: "/retail" },
              { text: "City Planning", page: "/cityplanning" },
            ].map((item, index, list) => {
              return (
                <Grid
                  key={item.text}
                  item
                  style={{ marginBottom: index + 1 !== list.length ? 15 : 0 }}
                >
                  <Link
                    to={item.page}
                    className="mobile subText"
                    style={{ textDecoration: "none" }}
                  >
                    {item.text}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            item
            xs={6}
            sm={isMobile ? 6 : 2}
            lg={3}
            direction="column"
            className="tablet flex grid align"
          >
            <Grid item className="link text">
              About
            </Grid>
            {[
              { text: "Team", page: "/aboutus" },
              { text: "Careers", page: "/careers" },
              { text: "Press", page: "/press" },
              { text: "Demo", page: "/demo" },
            ].map((item, index, list) => {
              return (
                <Grid
                  item
                  style={{ marginBottom: index + 1 !== list.length ? 15 : 0 }}
                >
                  <Link
                    to={item.page}
                    className="mobile subText"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {item.text}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            item
            container
            sm={isMobile ? 12 : 4}
            lg={3}
            direction="column"
            className="tablet flex"
            style={{paddingTop: "4px"}}
          >
            <Grid
              container
              item
              sm={3}
              direction="column"
              className="desktop display tablet width"
            >
              <Grid item>
                <Link
                  to="/contactus"
                  style={{ textDecoration: "none"}}
                  className="link text"
                >
                  Contact
                </Link>
              <Grid
              container
              item
              sm={9}
              direction="column"
              style={{ maxWidth: "100%"}}
              className="grid item tablet view"
            >
              <Grid
                item
                className="link text"
                style={{
                  marginBottom: 0,
                  fontSize: 14,
                  marginTop: 20,
                }}
              >
                Sister Companies
              </Grid>
              <Grid
                container
                className="centered container"
              >
                <Grid
                  item
                  className="vigilus padding"
                  style={{ height: 70 }}
                >
                  <Link to="//www.vigilus.com" target="_blank" rel="noopener">
                    <img
                      src={IMAGES.vigilusLogoWhite}
                      alt="Vigilus logo"
                      style={{ height: "70%", opacity: "40%", marginTop: 20 }}
                    />
                  </Link>
                </Grid>
                <Grid
                  item
                  className="griffin padding"
                  style={{ height: 70 }}
                >
                  <Link
                    to="//www.griffingames.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={IMAGES.griffinGames}
                      alt="Griffin Games logo"
                      style={{ height: "90%", opacity: "40%", marginTop: 20 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
              container
              item
              sm={9}
              direction="column"
              className="non tablet flex width"
            >
              <Grid
                item
                className="link text sister companies"
              >
                Sister Companies
              </Grid>
              <Grid
                container
                className="centered container"
              >
                <Grid
                  item
                  className="vigilus padding"
                  style={{ height: 70 }}
                >
                  <Link to="//www.vigilus.com" target="_blank" rel="noopener">
                    <img
                      src={IMAGES.vigilusLogoWhite}
                      alt="Vigilus logo"
                      style={{ height: "70%", opacity: "40%", marginTop: 20 }}
                    />
                  </Link>
                </Grid>
                <Grid
                  item
                  className="griffin padding"
                  style={{ height: 70 }}
                >
                  <Link
                    to="//www.griffingames.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={IMAGES.griffinGames}
                      alt="Griffin Games logo"
                      style={{ height: "90%", opacity: "40%", marginTop: 20 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
        </Grid>

        <Divider
          light
          className="divider height"
          style={{ backgroundColor: "transparent" }}
        />
        <Box
          style={{
            height: 1,
            backgroundColor: "rgb(255,255,255,0.2)",
            width: "100%",
          }}
        />
      </Grid>
      <Box
      className="mobile direction"
      >
        <Grid
          container
          md={10}
          className="medium padding centered container"
        >
          <Link to="/subscription" style={{ textDecoration: "none" }}>
            <Typography
              className="legal info text"
            >
              Subscription Agreement
            </Typography>
          </Link>
            <Typography
              className="legal info text mobile display"
              style={{
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              |
            </Typography>
          <Link to="/privacy" style={{ textDecoration: "none" }}>
            <Typography className="legal info text">
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          md={3}
          lg={2}
          className="copyright grid"
        >
          <Typography
            className="legal info text padding change"
          >
            &copy; {year} Crimer
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
}