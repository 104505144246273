import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import BlueImage from "../components/SolutionPagesComponents/BlueImage";
import BorderedDivider from "../components/SolutionPagesComponents/BorderedDivider";
import BulletedParagraph from "../components/SolutionPagesComponents/BulletedParagraph";
import IMAGES from "../components/ImageImport";
import HomeDemo from "../components/HomeDemo";
import "../components/css/SolutionPageStyles.css"

const heroTitle = "Real Estate Developers";

export default function RealEstate() {
  useEffect(() => {
    document.title = "Crimer | Real Estate";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");

  const heroImage = isMobile ? IMAGES.solutionPageImages.realEstate.heroImageMobile : IMAGES.solutionPageImages.realEstate.heroImage;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 55, backgroundColor: "transparent" }} />
        <RedHeader
          className="solution header"
        >
          Crime as a Calculable Risk
        </RedHeader>
        <Divider style={{ height: 35, backgroundColor: "transparent" }} />
        <Typography className={"subsection solution desktop display"}>
        Properties are more than just property. As with any investment,
        the true worth of real estate is tied into its value over time.
        Just like the many environmental factors that affect a property’s worth over its lifetime,
        an area’s crime presence significantly impacts value.
        <br/><br/>
        Agents and investors already understand the value of data science and just how heavily it impacts their fields.
        Now Crimer brings the future of data science techniques to the discipline of criminology.
        </Typography>
        <Typography className={"subsection solution mobile display"}>
        Properties are an investment. And just like any investment, it’s true worth is tied into its value over time.
        <br/><br/>
        Now, just like any other environmental factor, crime risk over time can be accurately assessed through Crimer’s prediction platform. We’re giving you the tools of tomorrow’s information arms race.
        </Typography>
        <Divider style={{ height: 70, backgroundColor: "transparent" }} />
        <Box
          className="header box"
        >
          <BorderedDivider className="solution desktop display" style={{ width: "9.5vw" }} />
          <RedHeader className="solution header">
          Take a Sneak {!isMobile && <br />}Peek into the Future
          </RedHeader>
          <BorderedDivider className="solution desktop display" style={{ flex: 1 }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage showRedLine className="solution mobile display" style={{ marginBottom: 20 }} src={IMAGES.solutionPageImages.realEstate.sub1Mobile} />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
            <BlueImage showRedLine className="solution desktop display" style={{ marginRight: 50, maxWidth: 800 }} src={IMAGES.solutionPageImages.realEstate.sub1} />
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Know the Property">
            Crimer provides both agents and resellers with reliable, data-driven crime forecasts.
            Whether it’s choosing to sell or invest in a property,
            Crimer provides an intelligent edge over the competition.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Trust the Location">
            While other location-oriented crime prediction services use outdated and myopic hotspot methods,
            Crimer considers factors like weather, time, location, terrain, population density,
            and a variety of others in its calculations.
            Crimer’s multi-dimensional analysis tracks crime trends as they change from location to location,
            staying accurate and relevant for up to years in advance.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Get the Best Value">
              No one sells a house without knowing what weather conditions will likely affect its value.
              Why should it be any different with crime? Why shouldn’t local drug abuse,
              robberies, and violent crimes affect your valuations?
            </BulletedParagraph>
          </Box>
        </Box>
        <Divider className="solution divider" />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
          <RedHeader className="solution header">
            Sell with {!isMobile && <br />}Confidence
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage
          className= "solution mobile display"
            showRedLine
            style={{ marginBottom: 20 }}
            src={IMAGES.solutionPageImages.realEstate.sub2Mobile}
          />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Learn the Area">
              Customers always want to know what crime is like in the nearby area,
              not just for their personal and financial safety, but for the safety of their loved ones as well.
              Why shouldn’t you have that information available and readily accessible?
              Why shouldn’t you have more than just an educated guess based off of minimal data?
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="No More Guesswork">
            No more guessing around. Crimer’s easy to use,
            extremely customizable prediction platform is
            tailored to suit your individual needs and preferences.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Knowledge is Power">
              Sell with confidence, and operate with the knowledge that one
              of the most powerful commercially available crime prediction
              services is part of your information network.
            </BulletedParagraph>
          </Box>
            <BlueImage
              showRedLine
              className='solution desktop display'
              style={{ marginLeft: 50, maxWidth: 800 }}
              src={IMAGES.solutionPageImages.realEstate.sub2}
            />
        </Box>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
      </Box>
      <HomeDemo  demoFade={false}/>
    </PageTemplate>
  );
}
