import { Box, useMediaQuery } from "@material-ui/core";
import { SetStateAction, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./css/HomePress.css";
import HomePressItem from "./HomePressItem";
import IMAGES from "./ImageImport";

export default function HomePress() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  const [focusedStory, setStoryIndex] = useState(0);
  const pressStories = [
    {
      title: "Social media, police reports used to predict future crimes",
      subtitle: "Nov. 4, 2019. BATON ROUGE, LA",
      url: "https://www.businessreport.com/newsletters/lsu-student-entrepreneurs-using-tech-to-predict-crime",
      image: IMAGES.pressDefaultImageSVG,
    },
    {
      title: "LSU student entrepreneurs using tech to predict crime",
      subtitle: "Nov. 1, 2019. BATON ROUGE, LA",
      url: "https://www.businessreport.com/newsletters/lsu-student-entrepreneurs-using-tech-to-predict-crime",
      image: IMAGES.homePressItemBackground,
    },
    {
      title: "From Student Project to Startup: The Evolution of Crimer",
      subtitle: "Oct. 29, 2019. BATON ROUGE, LA",
      url: "https://www.businessreport.com/newsletters/lsu-student-entrepreneurs-using-tech-to-predict-crime",
      image: IMAGES.solutionsMapJPG,
    },
  ];

  //TODO find solution to anchor mode not working on Percy
  return (
    <div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
      <Carousel
        showArrows={false}
        centerMode={!isTablet}
        infiniteLoop
        swipeable
        className="carousel width"
        emulateTouch
        autoPlay={true}
        interval={5000}
        swipeScrollTolerance={isMobile ? 20 : 100}
        showStatus={false}
        onChange={(index: SetStateAction<number>) => setStoryIndex(index)}
        centerSlidePercentage={isTablet ? 66 : 33}
        onClickItem={() => {
          window.open(pressStories[focusedStory].url, "_blank");
        }}
      >
        {pressStories.map((item, index) => (
          <Box
            className="press stories"
            style={{
              margin: index === focusedStory ? 0 : "0px 20px",
            }}
          >
            <HomePressItem
              imgHeight={index === focusedStory ? 300 : 270}
              title={item.title}
              subtitle={item.subtitle}
              url={item.url}
              image={item.image}
            />
          </Box>
        ))}
      </Carousel>
    </div>
  );
}
