import { Typography, useMediaQuery } from "@material-ui/core";
import "../FeatureDescription.css";

interface HomeFeaturesDescriptionProps {
  imageLink: string;
  description: string;
}
export const HomeFeaturesDescription = (
  props: HomeFeaturesDescriptionProps
) => {
  const { imageLink, description } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const style = isMobile ? "FeaturesDescriptionAnimation" : "";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifySelf: "center",
          alignItems: 'center',
          flexDirection: "column",
          width: "90%"
        }}
      >
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: "center", width: "95%"}}>
          <img
            src={imageLink}
            alt=""
            loading="lazy"
            className={style + " feature description image"}
          />
          {/* red line */}
          <div
            className="feature description divider"
          />
        </div>
        <div style={{height: 20}} />
        <Typography
          variant="body1"
          className={style + " feature description text"}
        >
          {description}
        </Typography>
      </div>
    </>
  );
};
