import { Box, Typography, makeStyles } from "@material-ui/core";
import IMAGES from "./ImageImport";

const useStyles = makeStyles((theme) => ({
  bulletPoint: {
    top: 5,
    [theme.breakpoints.up(600)]: {
      top: 5,
      height: '1.5rem',
    },
    [theme.breakpoints.up(1000)]: {
      top: 5,
      height: '2rem',
    },
    [theme.breakpoints.up(2000)]: {
      height: '3rem',
    },
  },
  bulletText: {
    marginRight: 30,

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '.9rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '.5rem',
    },
    [theme.breakpoints.up(2000)]: {
      fontSize: '1.4rem',
    },
  }
}));

export default function BulletPoint(props: any) {
  const classes = useStyles();
  const { children, style, ...rest } = props;
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "flex-start",
        textAlign: "left",
        height: 'auto',
        ...style,
      }}
      {...rest}
    >
      <img
        src={IMAGES.redArrow}
        alt= "Bulleted arrow"
        loading="lazy"
        className={classes.bulletPoint}
        style={{ marginRight: 24,
          position: props.isMobile ? 'relative' : "relative",
          height: props.isMobile ? 20 : 'auto',
          width: props.isMobile ? 20 : 'auto'}}
      />
      <Typography variant="body1" className={classes.bulletText}>
        {children}
      </Typography>
    </Box>
  );
}
