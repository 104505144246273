import { Box, Divider, Typography, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { OutlinedButton } from "../components/CustomButtons";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import PressBox from "../components/PressBox";
import IMAGES from "../components/ImageImport";
import "../components/css/Press.css";

const pressData = [
  {
    title: "Social media, police reports used to predict future crimes",
    pressDate: "2019-11-04T12:04:03Z",
    summary: `BATON ROUGE, LA – A group of former and current LSU students is trying to predict crime and so far, they say they’ve just about figured it out. It’s called Crimer, and it’s based in Baton Rouge. Daniel Atkinson is a junior at LSU who found hims...`,
    link: "https://www.wafb.com/2019/11/05/social-media-police-reports-used-predict-future-crimes/",
    image: "wafb_logo_square.png",
  },
  {
    title: "LSU student entrepreneurs using tech to predict crime",
    pressDate: "2019-11-01T12:04:03Z",
    summary: `BATON ROUGE, LA – One might think that home burglaries happen on the darkest of nights when it’s difficult to see, but that kind of crime is actually more likely to occur on a clear night, with a full moon. Stormy evenings, it turns out, are a...`,
    link: "https://www.businessreport.com/newsletters/lsu-student-entrepreneurs-using-tech-to-predict-crime",
    image: "business_report_logo.jpg",
  },
  {
    title: "From Student Project to Startup: The Evolution of Crimer",
    pressDate: "2019-10-29T12:04:03Z",
    summary: `BATON ROUGE, LA – Alexander “Lex” Adams took an unconventional path to business. What started as an engineering class project at LSU morphed into a data-driven crime prediction company: Crimer. Using primary data pulled from police departments...`,
    link: "https://www.latechpark.com/blog/2019/10/29/from-student-project-to-startup-the-evolution-of-crimer",
    image: "la_tech_park.png",
  },
  {
    title:
      "$100K BR Entrepreneurship Week pitch competition narrowed to 11 semi-finalists, Crimer among them",
    pressDate: "2019-10-07T04:03Z",
    summary: `BATON ROUGE, LA – Baton Rouge Entrepreneurship Week, an annual startup business conference known as BREW, selected the top 11 semi-finalists for its $100,000 pitch competition in November. More than 40 startups applied to be part of the pitch c...`,
    link: "https://www.theadvocate.com/baton_rouge/news/business/article_f50dd0d4-e941-11e9-8c24-e7f3b9f9b23a.html",
    image: "advocate_square.jpg",
  },
  {
    title: "Crimer Partners with Lafayette Parish Sheriff's Office",
    pressDate: "2019-10-02T04:03Z",
    summary: `LAFAYETTE, LA – Alexander Adams, Crimer CEO, met with Sheriff Mark Graber in August to propose a partnership. The LPSO (Lafayette Parish Sheriff’s Office) employs over 500 sheriff deputies and boasts of their criminal re-entry program which, ac...`,
    link: "https://www.crimer.com/press/crimer-partners-lafayette/#top",
    image: "lafayette_sheriff_square.png",
  },
  {
    title: "Is your area safe? LSU students create crime prediction program",
    pressDate: "2019-09-05T04:03Z",
    summary: `BATON ROUGE, LA – Crime is a large concern for many students on the University's campus. In the past year, there have been several safety scares sparking campus-wide outcry and, more recently, a false report of an armed intruder on campus. Sinc...`,
    link: "https://www.lsureveille.com/news/is-your-area-safe-lsu-students-create-crime-prediction-program/article_ddc401d0-cf4e-11e9-b97e-eff3ffe164d7.html",
    image: "reveille_logo.jpg",
  },
  {
    title: "LSU class project turns into crime prediction company",
    pressDate: "2019-09-05T04:03Z",
    summary: `BATON ROUGE, LA – LSU computer software students have created a program they say can be used to predict crime in a geographic area and have turned it into a tech startup. The platform, called Crimer, was developed as a class project in an under...`,
    link: "https://www.businessreport.com/newsletters/lsu-class-project-turns-into-crime-prediction-company",
    image: "business_report_logo.jpg",
  },
  {
    title: "Crimer, Other Industry Leaders to Participate in MBA Class at LSU",
    pressDate: "2019-09-05T04:03Z",
    summary: `BATON ROUGE, LA – This upcoming semester, students at Louisiana State University have the chance to experience drafting, finalizing, and presenting proposals to companies in a real business environment. The class, titled “Disruption Entrepreneu...`,
    link: "https://www.crimer.com/press/crimer-other-industry-leaders-participate-mba-class-lsu/#top",
    image: "lsu_business_flores_mba_program_sqr.jpg",
  },
  {
    title: "Crime Prevention Through Crime Prediction",
    pressDate: "2019-09-04T04:03Z",
    summary: `BATON ROUGE, LA – What if the solution to solving crime, lowering murder rates, and fighting the opioid crisis could be found through a marriage of computer science and entrepreneurship? That’s exactly the goal of Crimer, a crime-prediction sof...`,
    link: "https://www.lsu.edu/eng/news/2019/09/crimercompany.php",
    image: "lsu_engineering_logo.png",
  },
  {
    title: "Crimer Releases Demo App on Google Play",
    pressDate: "2019-04-29T04:03Z",
    summary: `BATON ROUGE, LA – Crimer now has an app publically available for download on Google Play. The app features a demo version of CrimeRadar, Crimer’s front-end application, and can give accurate crime predictions for up to a year in advance. Engine...`,
    link: "https://www.crimer.com/press/crimer-releases-demo-app-google-play/#top",
    image: "crimeradarlogo_square.png",
  },
  {
    title:
      "Crime Prevention Bettered by Combining Computer Science and Entrepreneurship",
    pressDate: "2019-08-27T04:03Z",
    summary: `BATON ROUGE, LA – LSU is encouraging students to pair computer science and entrepreneurship as it empowers students to plan, design and optimize technology while instilling a wide range of skills in project management and business entrepreneurs...`,
    link: "https://www.lsu.edu/business/newsevents/2019/8-august/crime-prevention-bettered-by-combining-computer-science-and-entrepreneurship.php",
    image: "lsu_business_logo.png",
  },
  {
    title: "Crimer Invited to E² Luncheon as Industry Partner",
    pressDate: "2019-08-21T04:03Z",
    summary: `BATON ROUGE, LA – Crimer founder and CEO, Alexander Adams attended the E² end of event luncheon. The E² (Encounter Engineering) Bridge Camp provides incoming LSU freshman mentorship opportunities and a chance to build long-lasting relationships...`,
    link: "https://www.crimer.com/press/crimer-invited-to-e-luncheon-as-industry-partner/#top",
    image: "press_e2_thumb.jpg",
  },
];

const showLessItemsLength = 5;

export default function Press() {
  //const classes = useStyles();
  useEffect(() => {
    document.title = "Crimer | Press";
  }, []);

  const [showAllItems, setShowAllItems] = useState(false)


  return (
    <PageTemplate>
      <Box
        style={{
          width: '100%',
          background: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Hero
          heroImage={IMAGES.pressHeader}
          heroTitle="Press Center"
        />
        {/* Press Boxes */}
        {/* p is our element with properties
          i is the index
          we do this because this is all dynamic and react can get confused*/}
        <Box
          className="press container"
        >
          {(showAllItems ? pressData : pressData.slice(0,showLessItemsLength)).map((p, i) => {
            return (
              <PressBox
                key={"pressbox-" + i}
                title={p.title}
                pressDate={p.pressDate}
                summary={p.summary}
                link={p.link}
                image={p.image}
              />
            );
          })}
          <Divider
            className="press divider"
          />
          {pressData.length > showLessItemsLength && (<OutlinedButton
            variant="contained"
            style={{
              alignSelf: 'center',
              padding: 24,
              maxWidth: 382,
              height: 90,
              width: "100%",
              transition: 'background .5s, color .5s'
            }}
            onClick={() => setShowAllItems(prevState => !prevState)}
          >
            <div
          style={{
            fontSize: "1.45rem",
            width: "100%"
          }}>
            {showAllItems ? "View Less" : "View More"}
          </div>

          </OutlinedButton>)
          }
          <Divider style={{ height: 66, backgroundColor: "transparent" }} />
          <Typography style={{ fontSize: ".9rem", textAlign: "center", alignSelf: 'center' }}>
            For media inquiries, contact us at{" "}
            <a style={{ color: `${colors.lightRed}`, textDecoration: 'none'}} href={"mailto:media@crimer.com"}>
              media@crimer.com
            </a>
          </Typography>
          <Divider
            className="bottom divider"
          />
        </Box>
      </Box>
    </PageTemplate>
  );
}
