import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import IMAGES from "../components/ImageImport";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import BlueImage from "../components/SolutionPagesComponents/BlueImage";
import BorderedDivider from "../components/SolutionPagesComponents/BorderedDivider";
import BulletedParagraph from "../components/SolutionPagesComponents/BulletedParagraph";
import HomeDemo from "../components/HomeDemo";
import "../components/css/SolutionPageStyles.css"

const heroTitle = "City Planning";

export default function CityPlanning() {
  useEffect(() => {
    document.title = "Crimer | City Planning";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");

  const heroImage = isMobile ? IMAGES.solutionPageImages.cityPlanning.heroImageMobile : IMAGES.solutionPageImages.cityPlanning.heroImage;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Hero Image and Title */}
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 55, backgroundColor: "transparent" }} />
        <RedHeader
          className="solution header"
        >
          Using Data to Improve Lives
        </RedHeader>
        <Divider style={{ height: 35, backgroundColor: "transparent" }} />
        <Typography className="subsection solution desktop display" >
        Cities plan events to draw new residents, earn revenue, and
        promote a sense of community. These events can be vital to
        a city’s well-being, but the presence of crime is stifling to them.
        <br /> <br />
        Outside of events, crime negatively impacts communities overall,
        increasing tax burdens, reducing productivity through criminality,
        and decreasing social and financial investment in the community.
        Maintaining public security and the social capital of the people
        within is vital for the health of communities.
        </Typography>
        <Typography className='subsection solution mobile display' style={{marginBottom: 0}}>
        Crime hurts communities. Not just in the tangible damages done, but in the way it destabilizes the sense of community. Strategically plan ahead for public safety using Crimer’s predictive algorithms.
        </Typography>
        <Divider style={{ height: 70, backgroundColor: "transparent" }} />
        <Box
          className="header box"
        >
          <BorderedDivider className="solution desktop display" style={{ width: "9.5vw" }} />
          <RedHeader className="solution header">
            How Crimer{!isMobile && <br />} Helps You
          </RedHeader>
          <BorderedDivider className="solution desktop display" style={{ flex: 1 }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage showRedLine className="solution mobile display" style={{ marginBottom: 20 }} src={IMAGES.solutionPageImages.cityPlanning.sub1Mobile} />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
            <BlueImage showRedLine className="solution desktop display" style={{ marginRight: 50, maxWidth: 800 }} src={IMAGES.solutionPageImages.cityPlanning.sub1} />
          <Box
            className="bullet box"
          >
            <BulletedParagraph isWide={isWide} isMobile={isMobile} titleCard="Multi-variable Calculations">
            Unlike traditional hotspot methods, Crimer includes far more than just
            past crime data in its calculations. Crimer considers weather, terrain,
            time, location, population density, and other factors to accurately track
            crime trends, even up to several years in advance. Crimer’s reliable models
            help you improve and keep your communities safe
            </BulletedParagraph>
            <BulletedParagraph isWide={isWide} isMobile={isMobile} titleCard="Improved Event Planning">
            Keep event staff and attendees safe by planning events in low crime probability
            zones. Route parades through areas that maximize visibility while minimizing risk.
            </BulletedParagraph>
          </Box>
        </Box>
        <Divider className="solution divider" />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
          <RedHeader className="solution header">
            Safer Cities,{!isMobile && <br />} Better Communities
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage
            className= "solution mobile display"
            showRedLine
            style={{ marginBottom: 20 }}
            src={IMAGES.solutionPageImages.cityPlanning.sub2Mobile}
          />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
          <Box
            className="bullet box"
          >
            <BulletedParagraph isWide={isWide} isMobile={isMobile} titleCard="Strategic Community Outreach">
            Reduce violent crimes through strategically planned outreach programs.
             Keep a step ahead of crime trends by working with law enforcement to
             ethically prevent criminal behavior.
            </BulletedParagraph>
            <BulletedParagraph isWide={isWide} isMobile={isMobile} titleCard="Intelligent Urban Development">
            Parks can’t perform their function as public recreational spaces when crime is prevalent.
            Plan parks and other public spaces in areas where crime is less likely to occur.
            </BulletedParagraph>
            <BulletedParagraph isWide={isWide} isMobile={isMobile} titleCard="Ease of Use">
            No two cities face the same set of challenges. Crimer’s easy to use, extremely customizable
            prediction platform is tailor-made to suit your city’s individual needs and preferences.
            </BulletedParagraph>
          </Box>
            <BlueImage
              showRedLine
              className='solution desktop display'
              style={{ marginLeft: 50, maxWidth: 800 }}
              src={IMAGES.solutionPageImages.cityPlanning.sub2}
            />
        </Box>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
      </Box>
      <HomeDemo  demoFade={false}/>
    </PageTemplate>
  );
}
