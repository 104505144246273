import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Box } from "@material-ui/core";

export default function PageTemplate(props: React.PropsWithChildren<{}>) {
  return (
    <Box style={{ overflowX: "hidden" }}>
      <Header />
      {props.children}
      <Footer />
    </Box>
  );
}
