import {
  Box,
  Fade,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import ArrowDivider from "../components/ArrowDivider";
import { OutlinedButton } from "../components/CustomButtons";
import { colors } from "../components/customTheme";
import HomeDemo from "../components/HomeDemo";
import HomeFeatures from "../components/HomeFeatures";
import HomeSolutions from "../components/HomeSolutions";
import PageTemplate from "../components/PageTemplate";
import IMAGES from "../components/ImageImport";
import "../components/css/HomePage.css";

function handleScrollToWhyCrimer() {
  window.scroll({
    top: window.innerHeight,
    left: 0,
    behavior: "smooth",
  });
}

export default function HomePage() {
  useEffect(() => {
    document.title = "Crimer";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const [featuresFade, setFeaturesFade] = useState(false);
  const [solutionsFade, setSolutionsFade] = useState(false);
  const [demoFade, setDemoFade] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const featuresRef = useRef<any>(null);
  const solutionsRef = useRef<any>(null);
  const demoRef = useRef<any>(null);

  const onScroll = (e: any) => {
    //event handler to tell if a user is scrolling
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    //clean up function to prevent memory leaks
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      /*
    set y positions of components refered to
    current.getBoundingClientRect().top grabs pixels from the top of element to top of page
    window.innerHeight grabs height of page, subtract from first value to find when element reaches bottom of page
  */
    }
    /*const descriptionHeight =
      descriptionRef.current.getBoundingClientRect().top - window.innerHeight;*/
    const featuresHeight =
      featuresRef.current.getBoundingClientRect().top - window.innerHeight;
    const solutionsHeight =
      solutionsRef.current.getBoundingClientRect().top - window.innerHeight;
    const demoHeight =
      demoRef.current.getBoundingClientRect().top - window.innerHeight;

    /*if (0 > descriptionHeight) {
      setDescriptionFade(true);
    }*/
    if (0 > featuresHeight) {
      setFeaturesFade(true);
    }
    if (0 > solutionsHeight) {
      setSolutionsFade(true);
    }
    if (0 > demoHeight) {
      setDemoFade(true);
    }
  }, [scrollTop]);

  const homeImage = isMobile ? IMAGES.homePageBackgroundMobile : IMAGES.homePageBackground;

  //Removed fade because it didn't look as good as I thought it would. Will ask Daniel
  return (
    <PageTemplate>
      <Box style={{ minWidth: "100%", backgroundColor: colors.darkBlue }}>
        <Box
          className="top container"
        >
          <Box className="lines container" style={{backgroundImage: `url(${IMAGES.splashPageLines})`}}/>
          <Box
            className="landing logo container"
          >
            <img
              src={homeImage}
              alt="landing logo"
              style={{
                position: "absolute",
                opacity: 0.5,
                height: "95%",
                bottom: 20,
                zIndex: -11,
                pointerEvents: "none",
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              flexDirection: "column",
              marginTop: 100,
              zIndex: 100,
            }}
          >
            <img
              src={IMAGES.crimerWordmark}
              className="crimer wordmark"
              alt="word mark"
              loading="lazy"
            />
            <Fade in={true} timeout={2000}>
              <Typography
                variant="h3"
                className="text formatting crime prediction"
              >
                Crime Prediction of<br /> the Future
              </Typography>
            </Fade>
            <Fade in={true} timeout={2000}>
              <OutlinedButton
                onClick={handleScrollToWhyCrimer}
                variant="contained"
                className="investigate button"
              >
                Investigate
              </OutlinedButton>
            </Fade>
          </Box>
          <ArrowDivider style={{ zIndex: 1000 }} />
        </Box>
        <Box
          style={{
            width: "100%",
            zIndex: 1,
            background: colors.darkBlue,
            position: "relative",
          }}
        >
          <Box
            className="crime container"
          >
            <Typography
              variant="h3"
              className="text formatting crime header"
            >
              WHEN CRIME IS AFOOT, WE'RE ONE STEP AHEAD
            </Typography>
            <Typography className="home sub section mobile display">
              Protection through prevention; prevention through prediction. This is Crimer’s goal, and the focus of our technology.
            </Typography>
            <Typography
              className="home sub section desktop display"
              //style={{ maxWidth: 800 }}
            >
              Protection through prevention; prevention through prediction.
              This is Crimer’s goal, and the focus of our technology.
              <br></br>
              <br></br>
              Our innovative and powerful machine-learning algorithms accurately predict the risk of crime occurring at any given location,
              at any time.
              Armed with this knowledge,
              our clients make informed everyday decisions that protect the public safety for everyone.

            </Typography>
          </Box>
        </Box>
        <div ref={featuresRef}>
          <HomeFeatures featuresFade={featuresFade} />
        </div>
        <div ref={solutionsRef}>
          <HomeSolutions solutionsFade={solutionsFade} />
        </div>
        <div ref={demoRef}>
          <HomeDemo demoFade={demoFade} />
        </div>
      </Box>
    </PageTemplate>
  );
}
