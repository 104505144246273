import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import IMAGES from "../components/ImageImport";
import BottomMap from "../components/BottomMap";
import "../components/css/Contact.css";

//const apiKey = process.env.REACT_APP_STATIC_API_KEY;
var heroTitle = "Contact Us";
var heroImage = IMAGES.contactUsHeaderJPG;

export default function ContactUs() {
  useEffect(() => {
    document.title = "Crimer | Contact";
  }, []);

  return (
    <PageTemplate>
      <Box
        style={{
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 54, backgroundColor: "transparent" }} />
        <RedHeader
          className="contact header"
        >
          Get In Touch
        </RedHeader>
        <Divider style={{ height: 34, backgroundColor: "transparent" }} />
        {/* Description */}

        <Typography className="contact sub section">
          We want to be your organization's personal data-driven detective. We
          believe that anyone can benefit from Crimer's powerful crime
          prediction platform. Whether you're a growing business needing to
          secure new assets, a large corporation looking to reduce your losses,
          or a government agency with special interests, we're here to help.
        </Typography>
        {/* insert map of their location */}
        {/* insert email, name, subject, body, and submit button for inquiries */}

        <BottomMap careersPage={false}/>
      </Box>
    </PageTemplate>
  );
}
