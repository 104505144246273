const IMAGES = {
  aboutUsBackground: require("../assets/about-us-background.jpg").default,
  aboutUsBackgroundMobile: require("../assets/about-us-background_mobile.jpg").default,
  advancedLeftArrow: require("../assets/advance-left-arrow.svg").default,
  advancedRightArrow: require("../assets/advance-right-arrow.svg").default,
  calendarCheckFill: require("../assets/calendar-check-fill.svg").default,
  careerImage: require("../assets/career-image.png").default,
  careerImageMobile: require("../assets/career-image_mobile.jpg").default,
  chevronBullet: require("../assets/chevronBullet.svg").default,
  codeBoxFill: require("../assets/code-box-fill.svg").default,
  contactUsHeaderSVG: require("../assets/contact-us-header.svg").default,
  contactUsHeaderJPG: require("../assets/contactus_header.jpg").default,
  crimerLocationMarker: require("../assets/crimer_location_marker.svg").default,
  crimerLockup: require("../assets/crimer_lockup.svg").default,
  crimerWordmark: require("../assets/crimer_wordmark.svg").default,
  demoBackground: require("../assets/demo-background.jpg").default,
  earthFill: require("../assets/earth-fill.svg").default,
  griffinGames: require("../assets/griffin_games_logo.png").default,
  homePageBackground: require("../assets/home-page-logo-background.png").default,
  homePageBackgroundMobile: require("../assets/home-page-logo-background_mobile.png").default,
  homePressItemBackground: require("../assets/home-page-background.jpg").default,
  homeSolutionRealEstate: require("../assets/home-solution-city-planning.svg")
    .default,
  iconFrame: require("../assets/icon_frame.svg").default,
  landingPageLogo: require("../assets/landing_page_logo.png").default,
  openSourceFill: require("../assets/open-source-fill.svg").default,
  ourLocation: require("../assets/our-location.png").default,
  partnersInCrime: require("../assets/partners_in_crime.png").default,
  partnersInCrimeMobile: require("../assets/partners_in_crime_mobile.png").default,
  policeBack: require("../assets/police-back.jpg").default,
  pressGradientPNG: require("../assets/press_gradient.png").default,
  pressGradientSVG: require("../assets/press_gradient.svg").default,
  pressDefaultImagePNG: require("../assets/press-default-image.png").default,
  pressDefaultImageSVG: require("../assets/press-default-image.svg").default,
  pressHeader: require("../assets/pressHeader.jpg").default,
  redArrow: require("../assets/red-arrow.svg").default,
  requestDemoBackground: require("../assets/request-demo-background.jpg")
    .default,
  shieldUserFill: require("../assets/shield-user-fill.svg").default,
  solutionsMapJPG: require("../assets/solutionsMap.jpg").default,
  solutionsMapSVG: require("../assets/solutionsMap.svg").default,
  splashPageArrowPNG: require("../assets/splash_page_arrow.png").default,
  splashPageArrowSVG: require("../assets/splash_page_arrow.svg").default,
  splashPageBlackFade: require("../assets/splash_page_black_fade.svg").default,
  splashPageBottomeLeftLine:
    require("../assets/splash_page_bottom_left_line.svg").default,
  splashPageBottomeRightLine:
    require("../assets/splash_page_bottom_right_line.svg").default,
  splashPageCircle: require("../assets/splash_page_circle.svg").default,
  splashPageEye: require("../assets/splash_page_eye.svg").default,
  splashPageLines: require("../assets/landing_lines.png").default,
  straighQuotes: require("../assets/straight-quotes.svg").default,
  teamBackground:require("../assets/TeamBackground.svg").default,
  vigilusLogoWhite: require("../assets/vigilus-logo-white.svg").default,
  vigilusLogo: require("../assets/vigilus-logo.svg").default,
  worldMap: require("../assets/world-map.svg").default,
  profilePictures: {
    Lex: require("../assets/ProfilePictures/Lex.png").default,
    LexMobile: require("../assets/ProfilePictures/Lex_mobile.png").default,
    Ben: require("../assets/ProfilePictures/Ben.png").default,
    Charles: require("../assets/ProfilePictures/Charles.png").default,
    CharlesMobile: require("../assets/ProfilePictures/Charles_mobile.png").default,
    Scott: require("../assets/ProfilePictures/Scott.png").default,
    ScottMobile: require("../assets/ProfilePictures/Scott_mobile.png").default,
    TJ: require("../assets/ProfilePictures/TJ.png").default,
    TJMobile: require("../assets/ProfilePictures/TJ_mobile.png").default,
    Daniel: require("../assets/ProfilePictures/Daniel.png").default,
    DanielMobile: require("../assets/ProfilePictures/Daniel_mobile.png").default,
    Kym: require("../assets/ProfilePictures/Kym.png").default,
    KymMobile: require("../assets/ProfilePictures/Kym_mobile.png").default,
    Madeline: require("../assets/ProfilePictures/Madeline.png").default,
    MadelineMobile: require("../assets/ProfilePictures/Madeline_mobile.png").default,
    Wood: require("../assets/ProfilePictures/Wood.png").default,
    WoodMobile: require("../assets/ProfilePictures/Wood_mobile.png").default,
  },
  features:{
    streamlinedEmergencyServices: require("../assets/emergency-services.jpg").default,
    streamlinedEmergencyServicesMobile: require("../assets/emergency-services_mobile.jpg").default,
    riskReduction: require("../assets/risk-reduction.jpg").default,
    riskReductionMobile: require("../assets/risk-reduction_mobile.jpg").default,
    openSourceTechnology: require("../assets/open-source.jpg").default,
    openSourceTechnologyMobile: require("../assets/open-source_mobile.jpg").default,
    developerFriendlyAPI: require("../assets/developer-friendly-api-screen.jpg").default,
    developerFriendlyAPIMobile: require("../assets/developer-friendly-api-screen_mobile.jpg").default,
    nationalCoverage: require("../assets/national.jpg").default,
    nationalCoverageMobile: require("../assets/national_mobile.jpg").default,
    advancedDataSourcing: require("../assets/data-sourcing.jpg").default,
    advancedDataSourcingMobile: require("../assets/data-sourcing_mobile.jpg").default,
  },
  solutionHomePageImages:{
    lawEnforcement:require("../assets/home-solution-law-enforcement.jpg").default,
    lawEnforcementMobile:require("../assets/home-solution-law-enforcement_mobile.jpg").default,
    realEstate:require("../assets/home-solution-real-estate.jpg").default,
    realEstateMobile:require("../assets/home-solution-real-estate_mobile.jpg").default,
    medical:require("../assets/home-solution-medical.jpg").default,
    medicalMobile:require("../assets/home-solution-medical_mobile.jpg").default,
    insurance:require("../assets/home-solution-insurance.jpg").default,
    insuranceMobile:require("../assets/home-solution-insurance_mobile.jpg").default,
    retail:require("../assets/home-solution-retail.jpg").default,
    retailMobile:require("../assets/home-solution-retail_mobile.jpg").default,
    cityPlanning: require("../assets/home-solution-city-planning.jpg").default,
    cityPlanningMobile: require("../assets/home-solution-city-planning_mobile.jpg").default,
  },
  solutionPageImages:{
    lawEnforcement:{
      heroImage:require("../assets/solutionsBackground.jpg").default,
      heroImageMobile:require("../assets/solutionsBackground_mobile.jpg").default,
      sub1:require("../assets/cop.jpg").default,
      sub2:require("../assets/solutionsMap.jpg").default,
    },
    realEstate:{
      heroImage:require("../assets/solution-real-estate.jpg").default,
      heroImageMobile:require("../assets/solution-real-estate_mobile.jpg").default,
      sub1:require("../assets/real-estate-agent.jpg").default,
      sub1Mobile:require("../assets/real-estate-agent_mobile.jpg").default,
      sub2:require("../assets/house-hunting.jpg").default,
      sub2Mobile:require("../assets/house-hunting_mobile.jpg").default,
    },
    medical:{
      heroImage:require("../assets/solution-medical.jpg").default,
      heroImageMobile:require("../assets/solution-medical_mobile.jpg").default,
      sub1:require("../assets/rural-ambulance.jpg").default,
      sub1Mobile:require("../assets/rural-ambulance_mobile.jpg").default,
      sub2:require("../assets/ems.jpg").default,
      sub2Mobile:require("../assets/ems_mobile.jpg").default,
    },
    insurance:{
      heroImage:require("../assets/solution-insurance.jpg").default,
      heroImageMobile:require("../assets/solution-insurance_mobile.jpg").default,
      sub1:require("../assets/liability.jpg").default,
      sub1Mobile:require("../assets/liability_mobile.jpg").default,
      sub2:require("../assets/insurance.jpg").default,
      sub2Mobile:require("../assets/insurance_mobile.jpg").default,
    },
    retail:{
      heroImage:require("../assets/solution-retail.jpg").default,
      heroImageMobile:require("../assets/solution-retail_mobile.jpg").default,
      sub1:require("../assets/business-report.jpg").default,
      sub1Mobile:require("../assets/business-report_mobile.jpg").default,
      sub2:require("../assets/break-in.jpg").default,
      sub2Mobile:require("../assets/break-in_mobile.jpg").default,
    },
    cityPlanning:{
      heroImage:require("../assets/solution-city-planning.jpg").default,
      heroImageMobile:require("../assets/solution-city-planning_mobile.jpg").default,
      sub1:require("../assets/navigation.jpg").default,
      sub1Mobile:require("../assets/navigation_mobile.jpg").default,
      sub2:require("../assets/city-plan.jpg").default,
      sub2Mobile:require("../assets/city-plan_mobile.jpg").default,
    },
  }
};

export default IMAGES;
