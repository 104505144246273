import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import IMAGES from "../components/ImageImport";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import BlueImage from "../components/SolutionPagesComponents/BlueImage";
import BorderedDivider from "../components/SolutionPagesComponents/BorderedDivider";
import BulletedParagraph from "../components/SolutionPagesComponents/BulletedParagraph";
import HomeDemo from "../components/HomeDemo";
import "../components/css/SolutionPageStyles.css"

const heroTitle = "Insurance";

export default function Insurance() {
  useEffect(() => {
    document.title = "Crimer | Insurance";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");

  const heroImage = isMobile ? IMAGES.solutionPageImages.insurance.heroImageMobile : IMAGES.solutionPageImages.insurance.heroImage;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Hero Image and Title */}
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 55, backgroundColor: "transparent" }} />
        <RedHeader
          className="solution header"
        >
          The Plain Truth
        </RedHeader>
        <Divider style={{ height: 35, backgroundColor: "transparent" }} />
        <Typography className={"subsection"}>
        In times of great loss, insurance exists to get people back on their feet.
        As nice as that sounds, insurance agencies aren’t charities, and to make
        sure they can keep helping people, they rely on clients buying into their
        system and then not relying on their services later on. In other words, in
        order to keep providing service, insurance companies always need to know the odds.
        </Typography>
        <Divider style={{ height: 70, backgroundColor: "transparent" }} />
        <Box
          className="header box"
        >
          <BorderedDivider className="solution desktop display" style={{ width: "9.5vw" }} />
          <RedHeader className="solution header" >
            Balancing{!isMobile && <br />} the Odds
          </RedHeader>
          <BorderedDivider className="solution desktop display" style={{ flex: 1 }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage showRedLine className="solution mobile display" style={{ marginBottom: 20 }} src={IMAGES.solutionPageImages.insurance.sub1Mobile} />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
            <BlueImage showRedLine className="solution desktop display" style={{ marginRight: 50, maxWidth: 800 }} src={IMAGES.solutionPageImages.insurance.sub1} />
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Learning from the Past">
            Crimer puts you one step ahead of outdated hotspot methods
            by actively predicting which areas are likely to experience
            crime in the future, not just showing where crimes have been
            committed in the past. Crimer accurately predicts crime trends
            as they migrate due to weather, events, terrain, and police presence.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Risk Management">
            Don’t get taken by surprise. Crimer lets you know the risks
            ahead of time so you can service your clients, armed with reliable,
            relevant information. Crimer’s easy to use, extremely customizable
            prediction platform is tailor-made to suit individual needs and preferences.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Crime Category Sorting">
            Crimer categorizes predicted crime incidence, allowing the user to
            isolate crimes like arson, vandalism, or larceny, crimes relevant to
            different insurance policies.
            </BulletedParagraph>
          </Box>
        </Box>
        <Divider className="solution divider" />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
          <RedHeader className="solution header">
            Crimer on the{!isMobile && <br />} Lookout
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage
            showRedLine
            className= "solution mobile display"
            style={{ marginBottom: 20 }}
            src={IMAGES.solutionPageImages.insurance.sub2Mobile}
          />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Health Risk Management">
            Understand the potential risks to your clients. Crimer predicts and tracks
            violent crimes and drug use in an area.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Theft Prediction">
            Accurately assess the risks of car theft and damage. Crimer
            markedly improves an agency’s comprehensive auto insurance
            policies through superior information.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="White Collar Crime Prevention">
            Crimer also evaluates non-violent crimes such as fraud and embezzlement,
            one of the most potentially damaging risks insurance can cover. Know how
            likely you and your clients are to be at risk.
            <br></br><br></br>
            Don’t let yourself be a victim of chance. Crimer’s customizable
            solutions put you ahead of the game and keep you there.
            </BulletedParagraph>
          </Box>
            <BlueImage
              showRedLine
              className='solution desktop display'
              style={{ marginLeft: 50, maxWidth: 800 }}
              src={IMAGES.solutionPageImages.insurance.sub2}
            />
        </Box>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
      </Box>
      <HomeDemo  demoFade={false}/>
    </PageTemplate>
  );
}
