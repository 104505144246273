import { Grid, IconProps, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import { FeatureButton } from "./CustomButtons";
import RedHeader from "./RedHeader";
import { HomeFeaturesDescription } from "./HomeFeaturesDescription";
import { AlarmWarning, CalendarCheck, CodeBox, Earth, OpenSource, Shield } from "../assets/icons/Icons";
import "../components/css/HomeFeatures.css";

import IMAGES from "./ImageImport";
interface HomeFeaturesProps {
  featuresFade: boolean;
}

interface ResolutionProps{
    features: {
      title: string;
      description: string;
      icon: any;
      image: any;
    }[];
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function HomeFeatures(props: HomeFeaturesProps) {
  //const { featuresFade } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const features = [
    {
      title: "Streamlined Emergency Services",
      description:
      isMobile ?
      "By predicting when violent crime is likely to happen, emergency medical services can plan ahead and staff appropriately. By predicting where crime is likely to happen, law enforcement can increase patrols to preemptively discourage crime."
      :
        "By taking advantage of Crimer’s prediction platform, emergency medical services can staff appropriately when crime risk is high, and route efficiently to areas that might see incidence of violent crime. Law enforcement agencies can increase patrols to predicted crime hotzones, preemptively reducing the risk of crime.",
      icon: AlarmWarning,
      image: isMobile ? IMAGES.features.streamlinedEmergencyServicesMobile : IMAGES.features.streamlinedEmergencyServices,
      },
    {
      title: "Risk Reduction",
      description:
      isMobile ?
      "Crimer’s prediction platform generates accurate ‘crime risk forecasts’. Users can reduce risk to themselves by avoiding high risk zones."
      :
      "Crimer reduces both financial and physical risk by generating accurate “crime forecasts”. By avoiding predicted high-risk areas, businesses can prevent avoidable damage to property and theft, while individuals can keep themselves out of potential harm’s way.",
      icon: Shield,
      image: isMobile ? IMAGES.features.riskReductionMobile : IMAGES.features.riskReduction,
    },
    {
      title: "Open Source Technology",
      description:
      isMobile ?
      "Crimer commits to our vision of a safer community for all by making our core services as free and as open-source as possible."
      :
      "Crimer envisions a safer community for all through application of our services. We commit to this vision by making all of our core services as free and as open-source as possible. Organizations can benefit from CrimeRadar as a no-cost drop-in replacement when integrating their existing systems with Crimer’s advanced API.",
      icon: OpenSource,
      image: isMobile ? IMAGES.features.openSourceTechnologyMobile : IMAGES.features.openSourceTechnology,
    },
    {
      title: "Developer Friendly API",
      description:
      isMobile ?
      "Crimer extends a 99% uptime API service, providing accurate prediction coverage over the entire United States. Getting started is as easy as dragging and dropping."
      :
      "Crimer extends a 99% uptime API service to organizations according to their specifications and preferences to provide accurate and detailed forecasts for any area within the United States. With world-class documentation, getting started with Crimer’s API is a simple and easy process.",
      icon: CodeBox,
      image: isMobile ? IMAGES.features.developerFriendlyAPIMobile : IMAGES.features.developerFriendlyAPI,
    },
    {
      title: "National Coverage",
      description: "Our services aren’t locked by region. Crimer’s subscription packages include access to crime prediction across the entire United States.",
      icon: Earth,
      image: isMobile ? IMAGES.features.nationalCoverageMobile : IMAGES.features.nationalCoverage,
    },
    {
      title: "Advanced Data-Sourcing",
      description: "Crimer’s prediction platform does not rely solely on police data, which allows new clients to benefit from our services immediately. Our platform pulls data from a variety of vetted sources, which allows Crimer to offer a more complete picture of a crime incident than one single report could provide.",
      icon: CalendarCheck,
      image: isMobile ? IMAGES.features.advancedDataSourcingMobile : IMAGES.features.advancedDataSourcing,
    },
  ];

  return (
    <>
      <MobileFeatures features={features} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      <DesktopFeatures features={features} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
    </>
  )
}


function MobileFeatures(props:ResolutionProps){
  return(
    <Grid className="feature mobile display" container={true} style={{ marginBottom: 100 }}>
      <Grid item={true} container={true} xs={12} justifyContent="center">
        <RedHeader
          style={{
            marginBottom: 20,
            width: 400,
            textAlign: "center",
            maxWidth: "75%",
            fontSize: 28,
            marginTop: 30,
          }}
        >
          FEATURES
        </RedHeader>
      </Grid>
      <Grid
        item={true}
        container={true}
        xs={12}
        sm={6}
        style={{ padding: "0px 20px 0px"}}
        alignContent="space-between"
        justifyContent={"center"}
      >
        {props.features.map((item, index) => {
          return (
            <>
                <FeatureButton
                  index={index}
                  activeIndex={props.activeIndex}
                  setActiveIndex={props.setActiveIndex}
                  IconToRender={item.icon}
                >
                  {item.title}
                </FeatureButton>
              {index === props.activeIndex && (
                <HomeFeaturesDescription
                  imageLink={item.image}
                  description={item.description}
                ></HomeFeaturesDescription>
              )}
            </>
          );
        })}
      </Grid>
    </Grid>
  );
}

function DesktopFeatures(props: ResolutionProps){
  return(
    <Grid container={true} className="feature container desktop display">
      <Grid item={true} container={true} xs={12} justifyContent="center">
        <RedHeader
          style={{
            marginBottom: 64,
            width: 400,
            textAlign: "center",
            maxWidth: "100%",
            fontSize: 42,
            marginTop: 0,
          }}
        >
          FEATURES
        </RedHeader>
      </Grid>
      <Grid container style={{ alignItems:"flex-start" }}>
        <Grid
          item
          container
          xs={12}
          sm={6}
          className="feature buttons"
          alignContent="space-between"
        >
          {props.features.map((item, index) => {
            return (
              <FeatureButton
                index={index}
                activeIndex={props.activeIndex}
                setActiveIndex={props.setActiveIndex}
                IconToRender={item.icon}
              >
                {item.title}
              </FeatureButton>
            );
          })}
        </Grid>
        <Grid
          item={true}
          container={true}
          xs={12}
          sm={6}
          justifyContent={"flex-start"}
        >
          {props.features.map((item, index) => {
            return (
              index === props.activeIndex && (
                <HomeFeaturesDescription
                  imageLink={item.image}
                  description={item.description}
                ></HomeFeaturesDescription>
              )
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
