import { Box } from "@material-ui/core";
import { colors } from "../customTheme";

export default function BorderedDivider(props: any) {
  return (
    <Box
      className = {props.className}
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        ...props.style,
      }}
    >
      <div
        style={{
          height: 1,
          backgroundColor: colors.lightGrey,
          boxShadow: "0 0 2px white",
        }}
      />
      <div
        style={{
          height: 1,
          backgroundColor: colors.lightGrey,
          boxShadow: "0 0 2px white",
        }}
      />
    </Box>
  );
}
