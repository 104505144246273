import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import IMAGES from "../components/ImageImport";
import BottomMap from "../components/BottomMap";
import { CareersButton } from "../components/CustomButtons";
import "../components/css/Careers.css";


//const apiKey = process.env.REACT_APP_STATIC_API_KEY;
const heroTitle = "Careers";
//const heroImage = careerBackground;


export default function Careers() {
  useEffect(() => {
    document.title = "Crimer | Careers";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const [activeIndex, setActiveIndex] = useState(1);
  const CareersText = [
    {
      header: "Software with a Purpose",
      text: "Want to change the world? So do we. And we'll match you with the resources you need to do it. At Crimer, you’re working alongside the brightest and most passionate minds in software. Together, we're building the premiere solution to public safety.",
    },
    {
      header: "A New Chapter",
      text: "Crimer’s powerful prediction platform accurately assesses the risk of crime happening at any given location and time, putting our cutting-edge machine learning algorithms at the forefront of the public safety market.",
    },
    {
      header: "Unwavering Morality",
      text: "And don't worry, we've all seen Minority Report. We understand the power of big data and the challenges that come with using that data responsibly. Above all, we believe in the ethical treatment of people and data. You're a good person, so you shouldn't be asked to do bad things. We welcome whistleblowers who will keep us honest, and we strictly adhere to the eight tenets set by the Partnership on AI.",
    }
  ];

  const heroImage = isMobile ? IMAGES.careerImageMobile : IMAGES.careerImage;

  return (
    <PageTemplate>
      <Box
        style={{
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 54, backgroundColor: "transparent" }} />
        <Grid>
          <Grid
            item={true}
            container={true}
            xs={12}
            sm={6}
            direction="row"
            style={{
              padding: "0px 20px 40px",
              display:"flex",
              flexDirection:"row",
              width:"100%",
              maxWidth:"100%"
            }}
            alignContent="space-between"
            justifyContent={"center"}
          >
            <MobileButtons CareersText={CareersText} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            <DesktopButtons CareersText={CareersText} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
          </Grid>
        </Grid>
        <Divider style={{ height: 34, backgroundColor: "transparent" }} />
        {/* Description */}



        {/* insert map of their location */}
        {/* insert email, name, subject, body, and submit button for inquiries */}
        <Divider
          className="map divider"
        />
        <BottomMap careersPage={true} />
      </Box>
    </PageTemplate>
  );
}

function DesktopButtons( props:{
  CareersText: {
    header: string;
    text: string;
    }[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>}
){
  return(
    <>
      {props.CareersText.map((item, index) => {return (
              <>
                <CareersButton
                  className="desktop display"
                  id = {"CareerButton" + index}
                  index={index}
                  activeIndex={props.activeIndex}
                  setActiveIndex={props.setActiveIndex}
                >
                  {item.header}
                  {index === props.activeIndex && (
                  <Typography
                    className="career text"
                  >
                    {item.text}
                  </Typography>
                )}
                </CareersButton>
              </>
            );
          })}
    </>
  );

}

function MobileButtons(props: {
  CareersText: { header: string; text: string; }[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>}
){

    return (
      <>
        {props.CareersText.map((item, index) => { return (
          <>
            <CareersButton
              className="mobile display"
              id = {"CareerButton" + index}
              index={index}
              activeIndex={props.activeIndex}
              setActiveIndex={props.setActiveIndex}
            >
              {item.header} &nbsp;
            </CareersButton>
            {index === props.activeIndex && (
              <Typography
                className="mobile display career text"
              >
                {item.text}
              </Typography>
            )}
          </>
        );
      })}
    </>
    )
}