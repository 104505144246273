import { Box } from "@material-ui/core";

export default function BlueImage(props: any) {
  return (
    <Box
      className={props.className}
      style={{
        ...props.style,
        flex: 1,
        alignSelf: "flex-start",
        position: "relative",
      }}
    >
      <img
        loading="lazy"
        style={{
          width: "100%",
          minWidth: 0,
          pointerEvents: 'none',
          alignSelf: "start",
          // filter: "grayscale(100%)",
            mixBlendMode: 'luminosity',
        }}
        alt= ""
        src={props.src}
      />
      {/*<div*/}
      {/*  style={{*/}
      {/*    width: "100%",*/}
      {/*    height: "100%",*/}
      {/*    backgroundColor: "rgba(25, 34, 87, .37)",*/}
      {/*    top: 0,*/}
      {/*    position: "absolute",*/}
      {/*  }}*/}
      {/*/>*/}
      {/* red line */}
      {props.showRedLine && (<div
        style={{
          width: "25%",
          height: 4,
          backgroundColor: "red",
          bottom: 2,
          right: 0,
          position: "absolute",
          zIndex: 12
        }}
      />)}
    </Box>
  );
}
