import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import IMAGES from "../components/ImageImport";
import HomeDemo from "../components/HomeDemo";
import "../components/css/Demo.css";

//const apiKey = process.env.REACT_APP_STATIC_API_KEY;
const heroTitle = "Demo";

export default function Demo() {
  useEffect(() => {
    document.title = "Crimer | Demo";
  }, []);

  return (
    <PageTemplate>
      <Box
        style={{
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Hero
          heroTitle={heroTitle}
          heroImage={IMAGES.demoBackground}
        />
        <Divider style={{ height: 54, backgroundColor: "transparent" }} />
        <RedHeader
          className="demo header"
        >
          Features
        </RedHeader>
        <Divider style={{ height: 34, backgroundColor: "transparent" }} />
        {/* Description */}

        <Typography className="sub section">
        Crimer’s prediction platform has the power to radically shift the way our clients approach crime risk and prevention.
        For the first time in history, all the small causal factors that can encourage crime – the lighting, weather,
        foot traffic, building placement, previous crime incidence, and so many others
        – can be considered and calculated to track the risk of crime.
        We’ve moved past the outdated hotspot methods of yesterday.
        <br></br>
        <br></br>
        But our solutions aren’t locked away behind a million lines of incomprehensible code,
        either. Our customizable and easy-to-implement service packages mean our clients don’t have to wait long to enjoy the benefits of our platform.
        That means making and keeping businesses, cities, and communities safe.
        <br></br>
        <br></br>
        <Typography className="text exaggeration">Welcome to the future.</Typography>


        </Typography>
        {/* insert map of their location */}
        {/* insert email, name, subject, body, and submit button for inquiries */}
        <Divider
          className="divider height"
        />
        <HomeDemo demoFade={false}/>
      </Box>
    </PageTemplate>
  );
}
