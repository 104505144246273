import { createTheme } from "@material-ui/core/styles";

export const colors = {
  darkBlue: "#191D3B",
  lightRed: "#C80A13",
  white: "#FFFFFF",
  lightGrey: "#8D8C8C",
  darkGrey: "#1A1818",
  black: "000000",
};

export const theme = createTheme({
  typography: {
    fontFamily: ["openSans", "Roboto", "Arial"].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      text: {
        transition: "0.5s",
        transitionTimingFunction: "ease-in-and-out",
        color: colors.white,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
        fontSize: "16px",
        fontFamily: "xperia",
        letterSpacing: "1px",
        fontWeight: 600,
        "&:hover": {
          background: "rgba(255,255,255,0.95)",
          color: colors.black,
        },
      },
      contained: {
        transition: "0.3s",
        transitionTimingFunction: "ease-in-and-out",
        color: colors.white,
        borderRadius: 0,
        background: "inherit",
        backgroundColor: "inherit",
        border: "2px solid white",
        boxShadow: "0px 0px 12px rgba(255,255,255,0.6);",
        letterSpacing: "3px",
        fontSize: 24,
        paddingLeft: 128,
        paddingRight: 128,
        paddingTop: 24,
        paddingBottom: 24,
        marginTop: 16,
        marginBottom: 16,
        "&:hover": {
          backgroundColor: colors.white,
          color: colors.black,
        },
      },
    },
    MuiIconButton: {
      root: {
        transition: "0.3s",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,0.1)",
        },
      },
    },
    MuiTypography: {
      root: {
        color: colors.white,
      },
      h3: {
        color: colors.white,
        letterSpacing: "2px",
        fontFamily: "xperia, openSans, Roberto",
      },
      h4: {
        color: colors.white,
        letterSpacing: "5px",
        fontSize: "1.8rem",
        textTransform: "uppercase",
        fontWeight: 500,
        fontFamily: "Roboto",
      },
      h5: {
        color: colors.white,
        letterSpacing: "4px",
        lineHeight: "1.6",
        fontSize: "1.5rem",
        textTransform: "uppercase",
        fontWeight: 500,
        fontFamily: "xperia, openSans, Roboto",
      },
      body1: {
        letterSpacing: "4px",
        lineHeight: "1.9",
        fontFamily: "Roboto",
        textTransform: "uppercase",
      },
    },
    MuiTextField: {
      root: {
        fontFamily: "Roboto",
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: "Roboto",
        fontSize: "18px",
        "&$focused": {
          color: colors.white,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Roboto",
        fontSize: "20px",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "2px solid gray",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "2px solid white",
        },
        "&:after": {
          borderBottom: "3px solid white",
        },
      },
      disabled: {},
      focused: {},
      error: {},
    },
  },
});
