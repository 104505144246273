import { Box, Grid } from "@material-ui/core";
import React from "react";
import { OutlinedButton } from "../CustomButtons";
import { colors } from "../customTheme";
import DemoForm from "../DemoPageComponents/DemoForm";
import IMAGES from "../ImageImport";
import RedHeader from "../RedHeader";
import Form from "./Form";

// TODO: move into .env
const staticMap = "https://maps.googleapis.com/maps/api/staticmap?center=30.443911,-91.180894&maptype=roadmap&zoom=16&size=1280x1280&style=feature:road|color:0x4d5c72&style=feature:landscape|color:0x1a263b&style=feature:water|color:0x1a2542&style=feature:poi|visibility:off&key=AIzaSyAWXB0XVsykU9AyF5cJ1wMyQxQLVMdgoUg&style=feature:road|element:geometry.fill|saturation:0|visibility:on&style=feature:road|element:labels.text|color:0xffffff&style=feature:road|element:labels.text.fill|color:0xffffff|visibility:off&style=feature:road|element:labels.text.stroke|weight:0.64|color:0xffffff|visibility:on&style=feature:road.highway|element:all|visibility:on&style=feature:road.highway|element:geometry.stroke|visibility:off&style=feature:road.highway|element:labels.text.fill|visibility:off&style=feature:road.highway|element:labels.text.stroke|weight:2.99|visibility:on&style=feature:road.arterial|element:labels.icon|visibility:off"
const crimerLink = "https://www.google.com/maps/place/Crimer/@30.4439084,-91.1830541,17z/data=!3m1!4b1!4m5!3m4!1s0x8626a188ab144a19:0xd9b506f4780def2f!8m2!3d30.4438154!4d-91.1808621"

export default function MobileBottomMap(props: any) {

  const { careersPage, className } = props

  return (
    <div className={className}>
    <Box
      style={{
        position: 'relative',
        display: "flex",
        alignItems: 'center',
        padding: 16,
        height: 600,
        background: `linear-gradient(to left bottom, rgba(255, 255, 255, 0%) 20%, rgba(25, 29, 59, 70%) 30%, rgba(25, 29, 59) 100%), url(${staticMap}) no-repeat`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        flexDirection: 'column'
      }}
    >
        <RedHeader style={{fontSize: 22, textAlign: 'center', width: '75%', padding: '8px 16px' }}>Our Location</RedHeader>
        <div style={{height: 36}} />
          <OutlinedButton
            onClick={() => {
              const newWindow = window.open(crimerLink, '_blank', 'noopener,noreferrer')
              if (newWindow) newWindow.opener = null
            }}
            variant="contained"
            style={{
              textDecoration: "none",
              width: '80%',
              height: "15vh",
              display: "flex",
              justifyContent: 'center',
              fontSize: 11,
              fontFamily: "Roboto",
              maxWidth: 300,
              overflow: "hidden",
              padding: '8px 16px',
              backgroundColor: "rgba(255,255,255,0.2)",
              backgroundImage: "unset"
            }}
          >
            929 Government Street {<br />}
            Baton Rouge, LA 70802
          </OutlinedButton>
        <img src={IMAGES.crimerLocationMarker} alt="Location ping" style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', width: 64, height: 64 }} />

    </Box>
    { careersPage ? (<Form />) : (<DemoForm />) }
    </div>
  );
}
