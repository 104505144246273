import {
  Button,
  ButtonProps,
  makeStyles,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { colors } from "./customTheme";
import IMAGES from "./ImageImport";
import "../components/css/CustomButtons.css";


const useStyles = makeStyles((theme) => ({
  line: {
    position: "absolute",
    bottom: 8,
    right: 8,
    width: 96,
    height: 4,
    transition: "0.33s",
    transitionTimingFunction: "ease-in-and-out",
  },
  HomeSolutions: {
    transition: "0.3s",
    fontSize: "0.8rem",
    textAlign: "center",
    color: colors.white,
    [theme.breakpoints.up(1900)]:{
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up(2000)]:{
      fontSize: '2.5rem'
    }
  }
}));

export const OutlinedButton = withStyles(() => ({
  root: {
    fontFamily: "xperia",
    fontWeight: 400,
    letterSpacing: "7px",
    "&:hover>div>div>div": {
      background: colors.darkGrey,
    },
  },
}))((props: any) => {
  const { children, error: boolean, className, ...rest } = props;
  const classes = useStyles();
  const [color, setColor] = useState("white");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if(props.error){
      setColor("red")
    }
    else{
      setColor("white")
    }
  }, [props.error])

  return (
    <Button
      className={className}
      {...rest}
      onMouseEnter={() => setColor(isMobile ? "white" : colors.darkBlue)}
      onMouseLeave={() => setColor(props.error ? "red" : "white")}
    >
      <div style={{
        color: color,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>{children}</div>
      <div style={{ width: "100%", height: "100%", position: "absolute" }}>
        <div className={classes.line} style={{ backgroundColor: color }} />
      </div>
    </Button>
  );
});

interface FeatureButtonProps {
  IconToRender: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  index: number;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const FeatureButton = withStyles(() => ({
  text: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.2)",
    },
  },
}))((props: FeatureButtonProps & ButtonProps) => {
  const {
    index,
    activeIndex,
    setActiveIndex,
    IconToRender,
    children,
    ...rest
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");

  const reference = useRef<HTMLButtonElement>(null);

  return (
    <Button
      ref={reference}
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "flex-start",
        paddingLeft: 0,
        height: 75,
      }}
      aria-label="Feature"
      onClick={() => {
        setActiveIndex(index);

        const timeout = setTimeout(() => {
          if(isMobile && reference.current){
              reference.current.scrollIntoView({behavior: "smooth"});
        }}, 0);
        return () => clearTimeout(timeout);

      }}
      {...rest}
    >
      <img
        src={IMAGES.iconFrame}
        alt= ""
        style={{ width: 50, height: 50, marginRight: 32, marginLeft: 16 }}
      />
      <div style={{ position: "absolute", width: 50, background: "red" }}>
        <IconToRender
          style={{
            transition: "0.3s",
            position: "absolute",
            top: -12,
            right: -3,
            fill: activeIndex === index ? colors.lightRed : colors.white,
          }}
        />
      </div>
      <Typography
        variant="h4"
        className="feature button"
        style={{
          textAlign: "left",
          transition: "0.3s",
          color: activeIndex === index ? colors.lightRed : colors.white,
        }}
      >
        {children}
      </Typography>
    </Button>
  );
});

interface HomeSolutionsButtonProps {
  index: number;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const HomeSolutionsButton = withStyles(() => ({
  text: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
}))((props: HomeSolutionsButtonProps & ButtonProps) => {
  const { index, activeIndex, setActiveIndex, children, ...rest } = props;
  const buttonWidth = 225;

  const onClick = () => {
    setActiveIndex(index);
    const element = document.getElementById("container");
    if (activeIndex !== index){
      element!.scrollTo({
        left: (buttonWidth * index) - buttonWidth/8,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const element = document.getElementById("container");
    element!.scrollTo({
      left: (buttonWidth * activeIndex) - buttonWidth/8,
      behavior: "smooth"
    });
  },[activeIndex]);

  return (
    <Button
      className="solution button"
      style={{
        minWidth: buttonWidth,
        borderTop:
          activeIndex === index
            ? "1px solid red"
            : "1px solid rgba(255,255,255,0.7)",
        borderBottom:
          activeIndex === index
            ? "1px solid red"
            : "1px solid rgba(255,255,255,0.7)",
        borderRadius: 0,
      }}
      onClick={onClick}
      {...rest}
    >
      <Typography
        variant="h5"
        className="solution text"
        style={{
          color: colors.white,
        }}
      >
        {children}
      </Typography>
    </Button>
  );
});

export const Hyperlink = withStyles(() => ({
  root: {
    borderBottom: "2px solid white",
    fontWeight: 500,
    fontSize: "1.3rem",
    letterSpacing: "7px",
    padding: 0,
    "&:hover": {
      background: "rgba(255,255,255,0.2)",
      color: colors.white,
    },
  },
}))((props: any) => {
  const { children, ...rest } = props;
  return <Button {...rest}>{children}</Button>;
});

export const FooterButton = withStyles(() => ({
  text: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
}))((props: ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "flex-start",
        marginTop: 16,
        marginBottom: 16,
        padding: 0,
      }}
      {...rest}
    >
      <Typography
        variant="h5"
        style={{
          transition: "0.3s",
          color: colors.white,
          fontSize: "1.05rem",
          textAlign: "left",
        }}
      >
        {children}
      </Typography>
    </Button>
  );
});

interface CareersButtonProps {
  index: number;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  className: string;
}

export const CareersButton = withStyles(() => ({
  text: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.2)",
    },
  },
}))((props: CareersButtonProps & ButtonProps) => {
  const {
    index,
    activeIndex,
    setActiveIndex,
    className,
    children,
    ...rest
  } = props;


  const buttonClass = className + " career button ";
  const [fullClassName, setFullClassName] = useState(buttonClass);

  const titleClass = "career title ";
  const [titleClassName, setTitleClassName] = useState(titleClass);

  useEffect(() => {
    if(index === activeIndex){
      setFullClassName(buttonClass + "active")
      setTitleClassName(titleClass + "active")
    }
    else{
      setFullClassName(buttonClass + "inactive")
      setTitleClassName(titleClass + "inactive")
    }
  }, [activeIndex, index, buttonClass]);

  return (
    <Button
      className={fullClassName}
      aria-label="Career Info"
      onClick={() => setActiveIndex(index)}
      {...rest}
    >
      <Typography
        variant="h4"
        className={titleClassName}
      >
        {children}
      </Typography>
    </Button>
  );
});