import { Box, Typography, useMediaQuery } from "@material-ui/core";
import ArrowDivider from "./ArrowDivider";
import '../components/css/HeroImage.css'

export default function Hero(props: any) {

  return (
    <Box
      className="hero container"
      style={{
        backgroundImage: `url(${props.heroImage}) `,
      }}
    >
    <Box
      className="hero-title container" />
      <Typography
        className="hero title"
        style={{
          ...props.style
        }}
      >
        {props.heroTitle}
      </Typography>
      <ArrowDivider />
    </Box>
  );
}
