import { Box } from "@material-ui/core";
import { OutlinedButton } from "../CustomButtons";
import IMAGES from "../ImageImport";
import RedHeader from "../RedHeader";
import Form from "./Form";
import React from "react";
import DemoForm from "../DemoPageComponents/DemoForm";

// TODO: move into .env
const staticMap = "https://maps.googleapis.com/maps/api/staticmap?center=30.443911,-91.184494&maptype=roadmap&zoom=16&size=1280x1280&scale=4&style=feature:road|color:0x4d5c72&style=feature:landscape|color:0x1a263b&style=feature:water|color:0x1a2542&style=feature:poi|visibility:off&key=AIzaSyAWXB0XVsykU9AyF5cJ1wMyQxQLVMdgoUg&style=feature:road|element:geometry.fill|saturation:0|visibility:on&style=feature:road|element:labels.text|color:0xffffff&style=feature:road|element:labels.text.fill|color:0xffffff|visibility:off&style=feature:road|element:labels.text.stroke|weight:0.64|color:0xffffff|visibility:on&style=feature:road.highway|element:all|visibility:on&style=feature:road.highway|element:geometry.stroke|visibility:off&style=feature:road.highway|element:labels.text.fill|visibility:off&style=feature:road.highway|element:labels.text.stroke|weight:2.99|visibility:on&style=feature:road.arterial|element:labels.icon|visibility:off"
const crimerLink = "https://www.google.com/maps/place/Crimer/@30.4439084,-91.1830541,17z/data=!3m1!4b1!4m5!3m4!1s0x8626a188ab144a19:0xd9b506f4780def2f!8m2!3d30.4438154!4d-91.1808621"


export default function DesktopBottomMap(props: any) {

  const { careersPage, className } = props

  return (
    <Box
      className={className}
      style={{
        position: "relative",
        alignItems: 'center',
        width: "100vw",
        height: "1000px",
        background: `linear-gradient(to left top, rgba(255, 255, 255, 0%) 20%, rgba(25, 29, 59, 90%) 50%, rgba(25, 29, 59) 100%), url(${staticMap}) no-repeat`,
        backgroundPosition: "center",
        backgroundSize: "100%",
        justifyContent: "flex",
      }}
    >
      <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '5%', paddingBottom: '5%',}}>
        <div style={{maxWidth: 412, width: '100%'}}>
          { careersPage ? (<Form />) : (<DemoForm />) }
        </div>
      </div>
      <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
        <div style={{position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', top: -300}}>
          <RedHeader style={{fontSize: 40, textAlign: 'center'}}>
            Our Location
          </RedHeader>
          <div style={{height: 100}} />
            <OutlinedButton
              onClick={() => {
                const newWindow = window.open(crimerLink, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
              }}
              variant="contained"
              style={{
                textDecoration: "none",
                display: "flex",
                fontSize: 11,
                fontFamily: "Roboto",
                height: "120%",
                overflow: "hidden",
                padding: 20,
                margin: 0,
                backgroundColor: "rgba(255,255,255,0.2)",
              }}
            >
              929 Government Street {<br />}
              Baton Rouge, LA 70802
            </OutlinedButton>
        </div>
        <img src={IMAGES.crimerLocationMarker}  alt="Location ping" />
      </div>
    </Box>
  );
}
