import { Box, Grid, makeStyles, } from "@material-ui/core";
import { Hyperlink } from "./CustomButtons";
import "./css/HomePress.css";
import LaunchIcon from '@material-ui/icons/Launch';

interface HomePressItemProps {
  title?: string;
  subtitle?: string;
  url?: string;
  image?: string;
  imgHeight?: number;
}

const useStyles = makeStyles((theme) => ({
  line: {
    position: "absolute",
    bottom: 8,
    right: 28,
    width: 76,
    height: 3,
    background: "white",
    transition: "0.3s",
  },
  pressItem: {
    zIndex: 100,
    width: "100%",
    display: "flex",
    border: "3px solid white",
    boxShadow: "0px 0px 30px rgba(255,255,255,0.3)",
    transition: "0.6s",
    transitionTimingFunction: "ease-in-and-out",
    "&:hover": {
      cursor: "pointer",
    },
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    /*"&:hover": {
      "& $line": {
        background: colors.darkGrey,
      },
      transform: "scale(1.03)",
    },*/
  },
}));

export default function HomePressItem(props: HomePressItemProps) {
  const classes = useStyles();
  const { title, subtitle, url, image } = props;

  return (
    <Box
      className={classes.pressItem}
      style={{
        height: props.imgHeight,
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",

      }}
    >
      {/*<img src={pressDefaultImage} style={{maxWidth: '100%', height: '100%', position: 'absolute', opacity: .4}} />*/}
      <Grid container direction="column" style={{backgroundColor: 'rgba(0,0,0,.4)',}}>
        <Grid
          item
          xs={5}
          container
          alignItems="center"
          justifyContent="center"
          //variant="h3"
          style={{
            letterSpacing: "4px",
            fontSize: "1em",
            textOverflow: "ellipsis",
            paddingLeft: '8px',
            paddingRight: '8px',
            textShadow: "2px 2px black",
            overflow: "hidden",
            zIndex: 1,
            color: "white",
            minWidth: "100%",
          }}
        >
          {title}
        </Grid>
        <Grid
          item
          xs={2}
          container
          alignItems="center"
          justifyContent="center"
          //variant="body1"
          style={{
            fontSize: ".8rem",
            zIndex: 1,
            color: "white",
            minWidth: "100%",
            textShadow: "2px 2px black",
            letterSpacing: "2px",
            textOverflow: "ellipsis",
          }}
        >
          {subtitle}
        </Grid>
        <Grid
          item
          xs={5}
          container
          className="link grid"
          alignItems="center"
          justifyContent="center"
        >
          <Hyperlink
            onClick={() =>
              window.open(
                url!,
                "_blank" // <- This is what makes it open in a new window.
              )
            }
            style={{ width: "auto", fontSize: "0.8em", textShadow: "2px 2px black", alignSelf: "center" }}
          >
            Read more{props.url && props.url.includes("crimer.com") ? "→" : <LaunchIcon style={{verticalAlign: "text-bottom"}}/>}
          </Hyperlink>
        </Grid>
      </Grid>
      <div className={classes.line} />
    </Box>
  );
}

HomePressItem.defaultProps = {
  title: "Title",
  subtitle: "Subtitle",
  url: "crimer.com",
};
