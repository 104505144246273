import { Typography, useMediaQuery } from "@material-ui/core";
import "../css/TeamProfile.css";

export default function TeamProfileBody(props: any) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");
  return (
    <Typography
      variant="body1"
      className="profile body"
      style={{
        ...props.style,
      }}
    >
      {props.children}
    </Typography>
  );
}
