import {
  Typography,
  Grid,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import BulletPoint from "./BulletPoint";
import RedHeader from "./RedHeader";
import IMAGES from "./ImageImport";
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  desktopSolutionsHeight: {
    [theme.breakpoints.down('md')]: {
      height: '70rem',
    },
    [theme.breakpoints.up('md')]: {
      height: '50rem',
    },
  },
  redHeader: {
    marginBottom: 32,
    textAlign: "center",
    justifyContent: "center",
    paddingLeft: '8px',
    width: "70%",
    maxWidth: "100%",
    fontSize: "2em",
    marginTop: 40,
    [theme.breakpoints.up("xs")]: {
      width: "90%",
      fontSize: "1.05em",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      fontSize: "2.25em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.5rem",
      width:'70%'
    },
    [theme.breakpoints.up(2000)]: {
      fontSize: "3rem",
      width:'50%'
    },
  },
  bulletPoint: {
    marginBottom: "5%",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "5%",
      fontSize: ".8rem",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: ".9rem",
      width: "90%",
    },
    [theme.breakpoints.up('md')]: {
      width:'80%',
      fontSize: '1.5rem'
    }
  },
  bulletPointText: {
    lineHeight: 2,
    fontSize:'1.25rem',
    [theme.breakpoints.up("xs")]: {
      letterSpacing:"3px"
    },
    [theme.breakpoints.up("sm")]: {
      letterSpacing:"6px"
    },
    [theme.breakpoints.down('lg')]: {
      lineHeight: 1.5,
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: 1.3,
      fontSize: '.8rem',
    },
    [theme.breakpoints.up(2000)]: {
      marginLeft: 34,
      fontSize: "2rem",
    },
  },
}));

export default function HomeSolutionContent(props: {activeIndex: number, setActiveIndex: React.Dispatch<React.SetStateAction<number>>}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:800px)");
  const isWide = useMediaQuery("(min-width:2000px)");
  const swipeThreshold = 40;
  const classes = useStyles();
  const { activeIndex, setActiveIndex } = props;
  const [startX, setStartX] = useState(0);

  const solutionsContent = [
    {
      title: "Law Enforcement",
      image: isMobile ? IMAGES.solutionHomePageImages.lawEnforcementMobile : IMAGES.solutionHomePageImages.lawEnforcement,
      bulletPoint1: "Future Force",
      bulletPoint2: "Improve efficiency by staffing according to predicted crime risk.",
      bulletPoint3: "Reduce crime incidence by maintaining police presence precisely where it’s needed.",
      bulletPoint4: "Streamline investigation through Crimer’s multidimensional data analysis."
    },
    {
      title: "Real Estate",
      image: isMobile ? IMAGES.solutionHomePageImages.realEstateMobile : IMAGES.solutionHomePageImages.realEstate,
      bulletPoint1: "Crime as a calculable risk",
      bulletPoint2: "Get the edge on the competition with reliable machine-learning driven data science.",
      bulletPoint3: "Gain valuable insight into tomorrow’s market by tracking future crime hotspots.",
      bulletPoint4: "Wow and reassure buyers with our sophisticated crime prediction platform."
    },
    {
      title: "Medical",
      image: isMobile ? IMAGES.solutionHomePageImages.medicalMobile : IMAGES.solutionHomePageImages.medical,
      bulletPoint1: "Saving Time, Saving Lives",
      bulletPoint2: "Keep staff in their top condition by scheduling shifts according to predicted crime risk.",
      bulletPoint3: "Improve response time with efficient and calculated patrols.",
      bulletPoint4: "Customize Crimer’s prediction platform to fit the needs of your area."
    },
    {
      title: "Insurance",
      image: isMobile ? IMAGES.solutionHomePageImages.insuranceMobile : IMAGES.solutionHomePageImages.insurance,
      bulletPoint1: "A Sneak Peek Into the Future",
      bulletPoint2: "Fine tune your rates according to predicted crime risk.",
      bulletPoint3: "Advise your clients with reliable and relevant risk information.",
      bulletPoint4: "Track different damage risks separately by type."
    },
    {
      title: "Retail",
      image: isMobile ? IMAGES.solutionHomePageImages.retailMobile : IMAGES.solutionHomePageImages.retail,
      bulletPoint1: "No More Risky Business",
      bulletPoint2: "Protect your customers and your brand with our reliable prediction platform.",
      bulletPoint3: "Plan store locations in low-risk areas.",
      bulletPoint4: "Enjoy scaling benefits from savings that grow alongside your business."
    },
    {
      title: "City Planning",
      image: isMobile ? IMAGES.solutionHomePageImages.cityPlanningMobile : IMAGES.solutionHomePageImages.cityPlanning,
      bulletPoint1: "City Planning",
      bulletPoint2: "Keep event staff and attendees safe by planning events in low crime probability zones.",
      bulletPoint3: "Reduce violent crimes through strategically planned outreach programs.",
      bulletPoint4: "Plan parks and other public spaces in areas where crime is less likely to occur."
    }
  ];

  const componentDidMount = () => {
    solutionsContent.forEach((picture) => {
        const img = new Image();
        img.src = picture.image;
        img.loading = "lazy"
    });
  }

  return (
    isMobile ?
    <div style={{display: 'flex', justifyContent: 'center', zIndex: 1}}>
      {componentDidMount()}
      <Carousel
        showArrows={false}
        swipeable
        selectedItem={activeIndex}
        onSwipeStart={(event) => {setStartX(event.changedTouches[0].screenX)}}
        onSwipeEnd={(event) => {
          let differenceX = startX - event.changedTouches[0].screenX;
          if(Math.abs(differenceX) >= swipeThreshold){
            if(differenceX > 0){ //right swipe
              setActiveIndex((activeIndex + 1) % 6);
            }
            else if(differenceX < 0){ //left swipe
              setActiveIndex((activeIndex + 5) % 6); //error if negative. result of -1 + 6
            }
          }
        }}
        infiniteLoop
        emulateTouch
        showStatus={false}
        swipeScrollTolerance={swipeThreshold}
        preventMovementUntilSwipeScrollTolerance
        width="100vw"
      >
       {solutionsContent.map((item, index) => (
         <div style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingBottom: 20}}>

          <RedHeader className={classes.redHeader}>{item.title}</RedHeader>
          <div style={{position: 'relative', marginBottom: 40}}>
            <img
              src={item.image}
              alt= ""
              loading="lazy"
              style={{
                width: "90%",
                pointerEvents: 'none',
                aspectRatio: "16/9",
                filter: "grayscale(100%)",
                mixBlendMode: "luminosity",
              }}
            />
            {/* red line */}
            <div
              style={{
                width: "25%",
                height: 4,
                backgroundColor: "red",
                bottom: -1,
                right: "5%",
                position: "absolute",
              }}
            />
          </div>
          <div style={{height:"70%", width: "95%", justifyItems: "left"}}>
            <BulletPoint isMobile={isMobile} className={classes.bulletPoint}>
              <div className={classes.bulletPointText}>
                {item.bulletPoint1}
              </div>
            </BulletPoint>
            <BulletPoint isMobile={isMobile} className={classes.bulletPoint}>
              <div className={classes.bulletPointText}>
                {item.bulletPoint2}
              </div>
            </BulletPoint>
            <BulletPoint isMobile={isMobile} className={classes.bulletPoint}>
              <div className={classes.bulletPointText}>
                {item.bulletPoint3}
              </div>
            </BulletPoint>
            <BulletPoint isMobile={isMobile} className={classes.bulletPoint} style={{paddingBottom: 10}}>
              <div className={classes.bulletPointText}>
                {item.bulletPoint4}
              </div>
            </BulletPoint>
          </div>
         </div>
       ))}
      </Carousel>
    </div>

    :

    <Grid container style={{ marginTop: 50, marginBottom: 70, zIndex: 1 }}>
      {componentDidMount()}
      <Grid item={true} xs={12} sm={6} >
        <div style={{position: 'relative',}}>
          <img
            src={solutionsContent[activeIndex].image}
            alt= ""
            loading="lazy"
            style={{
              width: "80%",
              aspectRatio: "4/3",
              pointerEvents: 'none',
              filter: "grayscale(100%)",
              mixBlendMode: "luminosity",
            }}
          />
          {/* red line */}
          <div
            style={{
              margin: 0,
              width: "25%",
              height: 4,
              backgroundColor: "red",
              bottom: 2,
              right: '10%',
              position: "absolute",
            }}
          />
        </div>
      </Grid>
      <Grid
        direction={'column'}
        item
        container
        sm={6}
        className={classes.desktopSolutionsHeight}
        style={{ zIndex: 3,}}
        justifyContent={"flex-start"}
      >

        <RedHeader className={classes.redHeader} style={{padding: isTablet ? '0px' : ''}}>{solutionsContent[activeIndex].title}</RedHeader>

        <Typography
          variant="body1"
          style={{ paddingLeft: 39, marginBottom: 32, textAlign: "start", fontSize: isWide? '1.75rem' : '1.5rem', padding: isTablet ? '0px' : '' }}
        >
          {solutionsContent[activeIndex].bulletPoint1}
        </Typography>
        <BulletPoint className={classes.bulletPoint}>
          {solutionsContent[activeIndex].bulletPoint2}
        </BulletPoint>
        <BulletPoint className={classes.bulletPoint}>
          {solutionsContent[activeIndex].bulletPoint3}
        </BulletPoint>
        <BulletPoint className={classes.bulletPoint}>
          {solutionsContent[activeIndex].bulletPoint4}
        </BulletPoint>
      </Grid>
    </Grid>
  );
}

