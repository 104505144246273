import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect } from "react";
import { colors } from "../components/customTheme";
import Hero from "../components/Hero";
import PageTemplate from "../components/PageTemplate";
import RedHeader from "../components/RedHeader";
import BlueImage from "../components/SolutionPagesComponents/BlueImage";
import BorderedDivider from "../components/SolutionPagesComponents/BorderedDivider";
import BulletedParagraph from "../components/SolutionPagesComponents/BulletedParagraph";
import IMAGES from "../components/ImageImport";
import HomeDemo from "../components/HomeDemo";
import "../components/css/SolutionPageStyles.css";
const heroTitle = "Law Enforcement Agencies";


export default function LawEnforcementPage() {
  useEffect(() => {
    document.title = "Crimer | Law Enforcement";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isWide = useMediaQuery("(min-width:2000px)");

  const heroImage = isMobile ? IMAGES.solutionPageImages.lawEnforcement.heroImageMobile : IMAGES.solutionPageImages.lawEnforcement.heroImage;

  return (
    <PageTemplate>
      <Box
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Hero Image and Title */}
        <Hero
          heroTitle={heroTitle}
          heroImage={heroImage}
        />
        <Divider style={{ height: 55, backgroundColor: "transparent" }} />
        <RedHeader
          className="solution header"
        >
          Times are changing
        </RedHeader>
        <Divider style={{ height: 35, backgroundColor: "transparent" }} />
        <Typography className='subsection solution desktop display' style={{marginBottom: 0}}>
        Before a patrol officer gets a call from dispatch, before their foot ever touches the gas pedal,
        intense exchanges of information have already taken place. Just simply maintaining a visible police presence,
        linked positively to the deterrence in crime, requires deep and complicated analyses.
        It’s not a stretch to say that lives depend on officers being at the right place at the right time.
        <br/>
        <br/>
        As departments continue to spread their force ever thinner to cover a growing population,
        an officer’s most valued asset is intelligence they can rely on. Unfortunately,
        they often find that in critically short supply.
        Departments spend billions implementing unprovable experimental software, just to try and stay ahead.
        Frankly, reliable information hasn’t been keeping up with the times. But that changes now.
        </Typography>
        <Typography className='subsection solution mobile display' style={{marginBottom: 0}}>
        Departments and agencies rely on outdated, obsolete hotspot methods or expensive and unprovable experimental software. Crimer’s machine-learning algorithms don’t just help fight crime; they help prevent them entirely.
        </Typography>
        <Divider style={{ height: 60, backgroundColor: "transparent" }} />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
          <RedHeader className="solution header">
            The Future of{!isMobile && <br />} Crime Prevention
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
        </Box>
        <Divider className="image divider" />
          <BlueImage showRedLine className="solution mobile display" style={{ marginBottom: 30 }} src={IMAGES.solutionPageImages.lawEnforcement.sub1} />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%",}}>
            <BlueImage showRedLine className='solution desktop display' style={{ marginRight: 50, maxWidth: 800 }} src={IMAGES.solutionPageImages.lawEnforcement.sub1} />
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Speed is Key">
            Officers don’t have to be minutes away when every second counts.
            Officers can be on the scene hours before anything has even begun.
            Crimer’s demonstrably accurate crime prediction software puts officers where they need to be,
            when they need to be there. Crimer saves time by putting officers closer to predicted crime hot zones,
            saving lives by preventing crime through police presence.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Time is Money">
            Crimer dramatically improves police time efficiency,
            reducing the overwork and staffing problems that plague many departments.
            Uniquely, Crimer also allows departments to set targeted goals by aiming to
            reduce certain types of predicted crimes through heavier patrol and community outreach.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Big Data, No Problem">
            Crimer also saves time at the office by handling and analyzing terabytes of crime data,
            leaving investigators free to put the pieces together without having to comb through digital piles of reports.
            </BulletedParagraph>
          </Box>
        </Box>
        <Divider
          className="solution divider"
        />
        <Box
          className="header box"
        >
          <BorderedDivider className='solution desktop display' style={{ flex: 1 }} />
          <RedHeader className="solution header">
            How Crimer makes{!isMobile && <br />} the difference
          </RedHeader>
          <BorderedDivider className='solution desktop display' style={{ width: "9.5vw" }} />
        </Box>
        <Divider
          className="image divider"
        />
          <BlueImage
            className= "solution mobile display"
            style={{ marginBottom: 20 }}
            showRedLine
            src={IMAGES.solutionPageImages.lawEnforcement.sub2}
          />
        <Box style={{ display: "flex", flexDirection: "row", width: "90%" }}>
          <Box
            className="bullet box"
          >
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Accurate Predictions">
            Traditional hotspot methods work by collecting previous crime incident
            data and using it to infer where future crime is likely to take place.
            The problem with this approach is that it assumes crime hotspots aren’t
            likely to change locations over time. In reality, crime hotspots shift frequently,
            disappearing and reappearing in different locations sometimes overnight.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="Past, Present, Future">
            Crimer considers far more than just past crime data in its calculations.
            Crimer’s advanced machine learning algorithms compile information from police reports,
            dispatch radio, and even social media, assigning risk based on weather, terrain, time, location,
            population density, and other publicly available data. Using this information,
            Crimer accurately tracks crime trends even up to several years in advance.
            </BulletedParagraph>
            <BulletedParagraph isMobile={isMobile} isWide={isWide} titleCard="One Step Ahead">
            Stay a step ahead of crime. Crimer’s easy to use,
            extremely customizable prediction platform is tailor-made
            to suit your department's individual needs and preferences.
            </BulletedParagraph>
          </Box>
            <BlueImage
              showRedLine
              className='solution desktop display'
              style={{ marginLeft: 50, maxWidth: 800 }}
              src={IMAGES.solutionPageImages.lawEnforcement.sub2}
            />
        </Box>
        <Divider style={{ height: 40, backgroundColor: "transparent" }} />
      </Box>
      <HomeDemo  demoFade={false}/>
    </PageTemplate>
  );
}
