import { Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";

export default function PageNotFound() {
  useEffect(() => {
    document.title = "Page Not Found";
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    document.title = "Error 404: Page Not Found";
  }, []);

  return (
    <>
      <Typography
        variant="h1"
        style={{
          textAlign: "center",
          color: "darkblue",
          marginTop: isMobile ? 12 : 38,
          marginBottom: isMobile ? 30 : 48,
          letterSpacing: "6px",
          fontSize: isMobile ? "1.5rem" : "2rem",
          fontWeight: isMobile ? "bolder" : "initial",
        }}
      >
        404: Page Not Found
      </Typography>
      <Typography
        variant="h2"
        style={{
          textAlign: "center",
          color: "darkblue",
          marginTop: isMobile ? 12 : 38,
          marginBottom: isMobile ? 30 : 48,
          letterSpacing: "6px",
          fontSize: isMobile ? "1.2rem" : "1.75rem",
          fontWeight: isMobile ? "bolder" : "initial",
        }}
      >
        Looks like this page doesn't exist.
        <br></br>
        Don't worry, we are still predicting crime!
      </Typography>
    </>
  );
}
