import { Typography } from "@material-ui/core";
import { colors } from "./customTheme"

export default function RedHeader(props: any) {
  const { children, className, style, ...rest } = props;
  return (
    <Typography
      variant="h3"
      className={className}
      style={{
        display: "flex",
        background: colors.lightRed,
        letterSpacing: "0.3em",
        fontWeight: "0",
        padding: "8px 32px 8px 32px",
        alignItems: "center",
        textAlign: "center",
        boxShadow: `0 1px 2px ${colors.lightRed}, 0 -1px 2px ${colors.lightRed}`,
        justifyContent: "center",
        ...style,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
